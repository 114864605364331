import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CircleService} from './services/circle.service';
import {PersistenceService} from './services/persistence.service';
import {ApiService} from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  version = '';

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private circles: CircleService,
    private persistence: PersistenceService,
    public api: ApiService
  ) {
    this.api.getVersion().subscribe(version => this.version = version);
    // TODO: Add recovery from persistence
    // this.actRoute.queryParamMap.subscribe(map => {
    //   const previousCircleId = persistence.readFormState()?.circleId ?? -1;
    //   const circleId = map.get('circleId');
    //
    //   // No Circle Id was passed via URL, using previous Circle Id if
    //   // available or the default one if none is available.
    //   if (circleId === null) {
    //     this.circles.initIfApplicable(previousCircleId);
    //     this.hasLoadedCircleId = true;
    //     return;
    //   }
    //
    //   const circleIdNum = Number.parseInt(circleId ?? '0', 10);
    //
    //   // Another circleId was passed via Url than stored in Storage.
    //   // Clearing current Form State to ensure clean Start.
    //   if (previousCircleId !== circleIdNum) {
    //     this.persistence.clearFormState();
    //     const state = new FormState();
    //     state.circleId = circleIdNum;
    //     this.persistence.persistFormState(state);
    //   }
    //
    //   this.circles.setCircleId(circleIdNum);
    //   this.hasLoadedCircleId = true;
    //   this.router.navigate([], { queryParams: { circleId: null }, queryParamsHandling: 'merge' });
    // });
  }
}
