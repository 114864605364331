import {ContactFrequency, Relationship, YesNoMaybe} from '../../grpc/api_pb';
import {TranslocoService} from '@ngneat/transloco';
import {CircleType} from '../components/person-add/person-add.component';

export class EnumTranslator {
  static getTextFromRelationship(ts: TranslocoService, relationship: Relationship): string {
    switch (relationship) {
      case Relationship.OTHER_RELATIONSHIP:
        return ts.translate('relationships.other');
      case Relationship.PARTNER:
        return ts.translate('relationships.partner');
      case Relationship.EX_PARTNER:
        return ts.translate('relationships.expartner');
      case Relationship.CHILD:
        return ts.translate('relationships.child');
      case Relationship.PARENTS:
        return ts.translate('relationships.parents');
      case Relationship.SIBLING:
        return ts.translate('relationships.sibling');
      case Relationship.RELATIVE:
        return ts.translate('relationships.relative');
      case Relationship.BEST_FRIEND:
        return ts.translate('relationships.bestFriend');
      case Relationship.FRIEND:
        return ts.translate('relationships.friend');
      // case Relationship.BETTER_KNOWN_PERSON:
      //   return ts.translate('relationships.acquaintance');
      case Relationship.COLLEAGUE:
        return ts.translate('relationships.colleague');
      // case Relationship.SUPERVISOR:
      //   return ts.translate('relationships.supervisor');
      case Relationship.PROFESSIONAL:
        return ts.translate('relationships.professional');
      default:
        return '';
    }
  }

  static getTextFromContactFrequency(ts: TranslocoService, frequency: ContactFrequency): string {
    switch (frequency) {
      case ContactFrequency.DAILY:
        return ts.translate('contactFrequency.daily');
      case ContactFrequency.SEVERAL_TIMES_A_WEEK:
        return ts.translate('contactFrequency.severalTimesAWeek');
      case ContactFrequency.ONCE_A_WEEK:
        return ts.translate('contactFrequency.onceAWeek');
      case ContactFrequency.SEVERAL_TIMES_A_MONTH:
        return ts.translate('contactFrequency.severalTimesAMonth');
      case ContactFrequency.ONCE_A_MONTH:
        return ts.translate('contactFrequency.onceAMonth');
      case ContactFrequency.SEVERAL_TIMES_A_YEAR:
        return ts.translate('contactFrequency.severalTimesAYear');
      case ContactFrequency.ONCE_A_YEAR:
        return ts.translate('contactFrequency.onceAYear');
      case ContactFrequency.LESS:
        return ts.translate('contactFrequency.less');
      default:
        return '';
    }
  }

  static getYesNoMaybeName(ts: TranslocoService, yesNoMaybe: YesNoMaybe, circleType: CircleType): string {
    switch (yesNoMaybe) {
      case YesNoMaybe.YES:
        return ts.translate('yesNoMaybe.yes');
      case YesNoMaybe.NO:
        return ts.translate('yesNoMaybe.no');
      case YesNoMaybe.MAYBE:
        return ts.translate('yesNoMaybe.maybe');
      case YesNoMaybe.PARTLY:
        return ts.translate('yesNoMaybe.partly');
      case YesNoMaybe.YES_MORE:
        return circleType === 'support' ? ts.translate('yesNoMaybe.yesMoreSupport') : ts.translate('yesNoMaybe.yesMoreAppreciation');
      case YesNoMaybe.YES_LESS:
        return circleType === 'support' ? ts.translate('yesNoMaybe.yesLessSupport') : ts.translate('yesNoMaybe.yesLessAppreciation');
      default:
        return '';
    }
  }
}
