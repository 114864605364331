<div class="form-container" *transloco="let t">
  <div [innerHTML]="t('welcome')" *ngIf="formState.currentStep === INITIAL_DATA"></div>
  <h2 *ngIf="getTitle()">{{ getTitle() }}</h2>
  <span class="hint" *ngIf="getHint()">{{ getHint() }}</span>

  <div class="initial-inputs-container" *ngIf="formState.currentStep === INITIAL_DATA">
    <div class="initial-inputs">
      <mat-form-field class="first-third" appearance="fill">
        <mat-label>{{ t('form.hints.namePlaceholder') }}</mat-label>
        <input [formControl]="nameControl" matInput autocomplete="off" maxlength="5" class="is-uppercase">
      </mat-form-field>
    </div>
    <mat-checkbox class="tos-checkbox" [formControl]="tosAcceptedControl"><span (click)="openTermsDialog('tos'); $event.preventDefault()">{{ t('form.titles.tos') }}</span></mat-checkbox>
    <mat-checkbox class="tos-checkbox" [formControl]="privacyAcceptedControl"><span (click)="openTermsDialog('privacy'); $event.preventDefault()">{{ t('form.titles.privacy') }}</span></mat-checkbox>
  </div>
  <ng-container *ngIf="formState.currentStep === CIRCLE_NOW_DRAG_DROP || formState.currentStep === CIRCLE_NOW_PICK">
    <app-person-canvas
      *ngIf="circleService.isCircleLoaded()"
      [circle]="this.formState.circles[this.formState.currentCircleIndexNow].circle"
      [savedPersons]="formState.circles[formState.currentCircleIndexNow].persons"
      (personChange)="onPersonsUpdate($event, formState.currentCircleIndexNow)"
      [viewMode]="formState.currentStep === CIRCLE_NOW_PICK ? 'pick' : 'drag_drop_now'"
    ></app-person-canvas>
  </ng-container>

  <ng-container *ngIf="formState.currentStep === QUESTIONS">
    <app-person-questions
      *ngIf="circleService.isCircleLoaded()"
      [persons]="formState.circles[formState.currentCircleIndexNow].persons"
      (personChange)="onPersonsUpdate($event, formState.currentCircleIndexNow)"
      [currentPersonIndex]="formState.currentPerson"
      [currentQuestionGroup]="formState.currentQuestionGroup"
      (currentPersonIndexChange)="formState.currentPerson = $event; persist()"
      (currentQuestionGroupChange)="formState.currentQuestionGroup = $event; persist()"
    >
    </app-person-questions>
  </ng-container>

  <ng-container *ngIf="formState.currentStep === CIRCLE_PAST_DRAG_DROP">
    <app-person-canvas
      *ngIf="circleService.isCircleLoaded()"
      [circle]="this.formState.circles[this.formState.currentCircleIndexPast].circle"
      [savedPersons]="formState.circles[formState.currentCircleIndexPast].persons"
      (personChange)="onPersonsUpdate($event, formState.currentCircleIndexPast)"
      [viewMode]="'drag_drop_past'"
    ></app-person-canvas>
  </ng-container>

  <div class="finish-msg" *ngIf="formState.currentStep === FINISH">
    <h2>{{ t('form.titles.finish') }}</h2>
    <span>{{ t('form.hints.finish') }}</span>
    <div class="buttons">
      <ng-container *ngIf="formState.currentStep === FINISH">
        <button mat-button (click)="savePdf()">{{ t('form.titles.save') }}</button>
        <div class="spacer"></div>
        <button mat-button color="primary" (click)="restartWithWarning()">{{ t('general.restartQuestionnaire') }}</button>
      </ng-container>
    </div>
    <ng-container *ngFor="let circleWrapper of formState.circles">
      <app-person-canvas
        [circle]="circleWrapper.circle"
        [savedPersons]="circleWrapper.persons"
        [viewMode]="'print_now'"
      ></app-person-canvas>
      <app-person-canvas
        [circle]="circleWrapper.circle"
        [savedPersons]="circleWrapper.persons"
        [viewMode]="'print_past'"
      ></app-person-canvas>
    </ng-container>
  </div>

  <div class="buttons" *ngIf="formState.currentStep !== FINISH">
    <button mat-button color="warn" [disabled]="formState.currentStep === 0" (click)="restartWithWarning()">{{ t('general.restartQuestionnaire') }}</button>
    <div class="spacer"></div>
    <button mat-button (click)="prev()" [disabled]="formState.currentStep === 0">{{ t('general.back' )}}</button>
    <button mat-button color="primary" (click)="next()" [disabled]="!isStepReady()">{{ isAtEnd() ? t('general.finish') : t('general.next') }}</button>
  </div>
</div>
