<app-person-canvas
  [savedPersons]="persons"
  [activePerson]="currentPerson"
  [viewMode]="'view'"
></app-person-canvas>
<div class="question-groups" *ngIf="questionGroups">
  <div class="question-group" [style.marginLeft]="getQuestionCardOffset(groupIndex) + '%'" *ngFor="let questionGroup of questionGroups; let groupIndex = index">
    <mat-card>
      <mat-card-title>
        {{ questionGroup.getTitle() }}
      </mat-card-title>
      <mat-card-content>
        <div class="question">
          <div class="question-text"></div>
          <div class="question-answer" *ngFor="let questionAnswer of questionGroup.getOptionsList()">
            {{ questionAnswer }}
          </div>
        </div>
        <div class="question" *ngFor="let question of questionGroup.getQuestionsList()">
          <div class="question-text">{{ getQuestionTitleWithPersonName(question) }}</div>
          <mat-radio-group [value]="this.getCurrentValueForQuestion(question)" (change)="onAnswer(question, $event.value)">
            <div class="question-answer" *ngFor="let questionAnswer of questionGroup.getOptionsList(); let a = index">
              <mat-radio-button [value]="a + 1"></mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
