import {Component} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loginError = false;

  form = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
  });

  constructor(public adminService: AdminService, private router: Router) {
    adminService.stateChange.pipe(take(1)).subscribe(authenticated => {
      if (authenticated) {
        // Logout implicit and do a hard reload to ensure empty memory
        adminService.logout().subscribe(value => document.location.reload());
      }
    });
  }

  login(): void {
    this.adminService.login(this.form.get('username')?.value, this.form.get('password')?.value)
      .subscribe(() => this.router.navigate(['/admin']), () => this.loginError = true);
  }

}
