import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AdminService} from '../../services/admin.service';
import {map, take} from 'rxjs/operators';

export function loggedInGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const adminService = inject(AdminService);
    const router = inject(Router);
    return adminService.stateChange.pipe(take(1), map(source => {
      if (!source) {
        return router.createUrlTree(['/login']);
      }
      return source;
    }));
  };
}
