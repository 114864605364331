<ng-container *transloco="let t">
  <ng-container *ngIf="!isCancelling">
    <div class="title-container">
      <h2>{{ t('personAdd.title') }}</h2>
      <img [src]="getAvatarPath()" />
    </div>
    <ng-container *ngIf="currentStep === NAME">
      <span class="hint">{{ t('personAdd.startHint') }}</span>

      <mat-form-field>
        <input matInput [formControl]="nameControl">
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="currentStep === PERSON_GROUP">
      <span class="hint">{{ t('personAdd.groupHint', {name: nameControl.value}) }}</span>

      <div class="binary-picker">
        <div class="binary-btn" (click)="isGroupControl.setValue(false)" [class.chosen]="isGroupControl.valid && isGroupControl.value === false">
          <div class="icon-wrapper">
            <img src="assets/avatar-person.svg">
          </div>
          <span class="binary-btn-hint">{{ t('personAdd.person') }}</span>
        </div>
        <div class="binary-btn" (click)="isGroupControl.setValue(true)" [class.chosen]="isGroupControl.valid && isGroupControl.value === true">
          <div class="icon-wrapper">
            <img src="assets/avatar-group.svg">
          </div>
          <span class="binary-btn-hint">{{ t('personAdd.group') }}</span>
        </div>
      </div>
      <mat-form-field *ngIf="isGroupControl.value === true">
        <mat-label>{{ t('personAdd.groupSize') }}</mat-label>
        <input matInput [formControl]="groupSizeControl" type="number">
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="currentStep === GENDER">
      <span class="hint">{{ t('personAdd.genderHint', {name: nameControl.value}) }}</span>
      <mat-form-field>
        <input matInput [formControl]="genderControl">
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="currentStep === AGE">
      <span class="hint">{{ t('personAdd.ageQuestion', {name: nameControl.value, average: isGroupControl.value ? t('personAdd.onAverage') : '' }) }}</span>

      <mat-form-field>
        <input matInput [formControl]="ageControl" type="number">
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="currentStep === RELATIONSHIP">
      <span class="hint">{{ t('personAdd.relationshipHint', {name: nameControl.value}) }}</span>

      <mat-form-field>
        <mat-label>{{ t('personAdd.relationship') }}</mat-label>
        <mat-select [formControl]="relationshipTypeControl">
          <mat-option *ngFor="let relationshipType of relationshipTypes" [value]="relationshipType">
            {{ getRelationshipTypeName(relationshipType) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="isOtherRelationShipType()">
        <mat-label>{{ t('personAdd.otherHint') }}</mat-label>
        <input matInput [formControl]="relationshipOtherTextControl">
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="currentStep === RELATIONSHIP_DURATION">
      <span class="hint">{{ t('personAdd.relationshipDurationHint', {name: nameControl.value}) }}</span>
      <mat-form-field>
        <mat-label>{{ t('personAdd.monthAndYear') }}</mat-label>
        <input hidden [max]="now" [matDatepicker]="dp">
        <input matInput readonly (click)="dp.opened ? dp.close() : dp.open()" [value]="getDurationDateAsString()">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp
                        startView="multi-year"
                        (yearSelected)="onDurationYearChosen($event)"
                        (monthSelected)="onDurationMonthChosen($event, dp)"
                        (closed)="onDateChosen()"
                        panelClass="month-picker">
        </mat-datepicker>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="currentStep === FREQUENCY_OF_CONTACT">
      <span class="hint">{{ t('personAdd.frequencyOfContactHint', {name: nameControl.value, average: isGroupControl.value ? t('personAdd.onAverage') : '' }) }}</span>

      <mat-form-field>
        <mat-label>{{ t('personAdd.relationship') }}</mat-label>
        <mat-select [formControl]="frequencyOfContactControl">
          <mat-option *ngFor="let frequency of frequencies" [value]="frequency">
            {{ getFrequencyName(frequency) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="currentStep === IS_QUEER">
      <span class="hint">{{ t('personAdd.isQueerHint', {name: nameControl.value}) }}</span>

      <mat-form-field>
        <mat-label>{{ t('personAdd.pickAnswer') }}</mat-label>
        <mat-select [formControl]="isQueerControl">
          <mat-option [value]="YesNoMaybe.YES">{{ getYesNoMaybeName(YesNoMaybe.YES) }}</mat-option>
          <mat-option *ngIf="isGroupControl.value === true" [value]="YesNoMaybe.PARTLY">{{ getYesNoMaybeName(YesNoMaybe.PARTLY) }}</mat-option>
          <mat-option [value]="YesNoMaybe.NO">{{ getYesNoMaybeName(YesNoMaybe.NO) }}</mat-option>
          <mat-option [value]="YesNoMaybe.MAYBE">{{ getYesNoMaybeName(YesNoMaybe.MAYBE) }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="currentStep === KNOWS_ABOUT_TRANS">
      <span class="hint">{{ t('personAdd.knowsAboutTransHint', {name: nameControl.value}) }}</span>

      <mat-form-field>
        <mat-label>{{ t('personAdd.pickAnswer') }}</mat-label>
        <mat-select [formControl]="knowsAboutTransControl">
          <mat-option [value]="YesNoMaybe.YES">{{ getYesNoMaybeName(YesNoMaybe.YES) }}</mat-option>
          <mat-option *ngIf="isGroupControl.value === true" [value]="YesNoMaybe.PARTLY">{{ getYesNoMaybeName(YesNoMaybe.PARTLY) }}</mat-option>
          <mat-option [value]="YesNoMaybe.NO">{{ getYesNoMaybeName(YesNoMaybe.NO) }}</mat-option>
          <mat-option [value]="YesNoMaybe.MAYBE">{{ getYesNoMaybeName(YesNoMaybe.MAYBE) }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="currentStep === WOULD_CHANGE_OPINION_IF_NOT_TRANS">
      <span class="hint">{{ t(data.circleType === 'support' ? 'personAdd.wouldChangeOpinionIfNotTransSupportHint' : 'personAdd.wouldChangeOpinionIfNotTransAppreciationHint', {name: nameControl.value}) }}</span>

      <mat-form-field>
        <mat-label>{{ t('personAdd.pickAnswer') }}</mat-label>
        <mat-select [formControl]="wouldChangeOpinionIfNotTransControl">
          <mat-option [value]="YesNoMaybe.YES_MORE">{{ getYesNoMaybeName(YesNoMaybe.YES_MORE) }}</mat-option>
          <mat-option [value]="YesNoMaybe.YES_LESS">{{ getYesNoMaybeName(YesNoMaybe.YES_LESS) }}</mat-option>
          <mat-option [value]="YesNoMaybe.NO">{{ getYesNoMaybeName(YesNoMaybe.NO) }}</mat-option>
          <mat-option [value]="YesNoMaybe.MAYBE">{{ getYesNoMaybeName(YesNoMaybe.MAYBE) }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <div class="buttons">
      <button mat-button color="warn" (click)="close(false)">{{ t('general.cancel') }}</button>
      <div class="spacer"></div>
      <button mat-button (click)="prev()" [disabled]="currentStep === 0">{{ t('general.back') }}</button>
      <button mat-button color="primary" (click)="next()" [disabled]="!isStepReady()">{{ currentStep + 1 === stepCount ? t('general.finish') : t('general.next') }}</button>
    </div>
  </ng-container>
  <ng-container *ngIf="isCancelling">
    <h2>{{ t('personAdd.cancelConfirm') }}</h2>
    <span class="cancel-hint">{{ t('personAdd.cancelExplanation') }}</span>
    <button mat-button (click)="isCancelling = false">{{ t('personAdd.backToEntry') }}</button>
    <button mat-button color="warn" (click)="close(false, true)">{{ t('personAdd.cancelAndDelete') }}</button>
  </ng-container>

</ng-container>
