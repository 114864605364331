import {Component, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {PersonWrapper} from '../../model/person-wrapper';
import {TranslocoService} from '@ngneat/transloco';
import {UntypedFormControl, Validators} from '@angular/forms';
import {CircleService} from '../../services/circle.service';
import {PersistenceService} from '../../services/persistence.service';
import {FormState} from '../../model/form-state';
import {Subject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';
import {ModalHelper} from '../../util/modal-helper';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {FormRestartComponent, FormRestartResult} from '../form-restart/form-restart.component';
import {PersonQuestionsComponent} from '../person-questions/person-questions.component';
import {GlobalChangeService, GlobalChangeType} from '../../services/global-change.service';
import {PersonCanvasComponent} from '../person-canvas/person-canvas.component';
import {formatDate} from '@angular/common';
import {TermsDialogComponent, TermsDialogOptions, TermsDialogType} from '../terms-dialog/terms-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CircleWrapper} from '../../model/circle-wrapper';
import {jsPDF} from 'jspdf';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss']
})
export class PersonFormComponent implements OnDestroy {

  @ViewChild(PersonQuestionsComponent) questionsComponent?: PersonQuestionsComponent;
  @ViewChildren(PersonCanvasComponent) canvasComponents?: QueryList<PersonCanvasComponent>;

  // Increment / Decrement this if new question groups are added in backend
  readonly QUESTION_GROUP_COUNT = 2;

  private destroy$ = new Subject<void>();

  readonly INITIAL_DATA = 0;
  readonly CIRCLE_NOW_DRAG_DROP = 1;
  readonly CIRCLE_NOW_PICK = 2;
  readonly QUESTIONS = 3;
  readonly CIRCLE_PAST_DRAG_DROP = 4;
  readonly FINISH = 5;

  STEP_COUNT = 6;

  formState!: FormState;
  nameControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]);
  tosAcceptedControl = new UntypedFormControl(false);
  privacyAcceptedControl = new UntypedFormControl(false);
  surveyPeriodOptions = Array.from(Array(7), (_, x) => x + 1);
  now = new Date();

  constructor(
    private ts: TranslocoService,
    public circleService: CircleService,
    private persistenceService: PersistenceService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private globalChange: GlobalChangeService,
    private api: ApiService
  ) {
    const readState = persistenceService.readFormState();
    if (readState) {
      this.formState = readState;
      console.log(this.formState);
      this.updateFormControlsFromFormState();
    } else {
      this.initializeFormState();
    }

    this.startListeningForFormControlChanges();
    this.startListeningForLanguageChange();
  }

  initializeFormState(): void {
    this.formState = new FormState();
    this.circleService.initialized$.pipe(take(1)).subscribe(() => {
      console.log('circles loaded');
      this.formState.circles = this.circleService.getCircles().map((circle, index) => new CircleWrapper(index, circle));
      this.persist();
    });
  }

  persist(): void {
    this.persistenceService.persistFormState(this.formState);
  }

  startListeningForFormControlChanges(): void {
    this.nameControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.formState.name = value;
      this.persist();
    });
    this.tosAcceptedControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.formState.tosAccepted = value;
      this.persist();
    });
    this.privacyAcceptedControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.formState.privacyAccepted = value;
      this.persist();
    });
  }

  startListeningForLanguageChange(): void {
    this.globalChange.changes.pipe(filter(type => type === GlobalChangeType.LANG_CHANGE), takeUntil(this.destroy$)).subscribe(_ => {
      this.circleService.reloadCircles();
    });
  }

  updateFormControlsFromFormState(): void {
    this.nameControl.patchValue(this.formState.name);
    this.tosAcceptedControl.patchValue(this.formState.tosAccepted);
    this.privacyAcceptedControl.patchValue(this.formState.privacyAccepted);
  }

  onPersonsUpdate(persons: PersonWrapper[], circleIndex: number): void {
    this.formState.circles[circleIndex].persons = persons;
    this.persist();
  }

  isStepReady(): boolean {
    switch (this.formState.currentStep) {
      case this.INITIAL_DATA:
        return this.nameControl.valid &&
          this.tosAcceptedControl.value === true &&
          this.privacyAcceptedControl.value === true;
      case this.CIRCLE_NOW_DRAG_DROP:
      case this.CIRCLE_PAST_DRAG_DROP:
        return this.formState.circles[this.formState.currentCircleIndexNow].persons.length > 0;
      case this.CIRCLE_NOW_PICK:
        return this.formState.circles[this.formState.currentCircleIndexNow].persons.filter(person => person.isImportant).length > 0;
      case this.QUESTIONS:
        return this.questionsComponent?.isStepReady() ?? false;
    }
    return true;
  }

  prev(): void {
    switch (this.formState.currentStep) {
      case this.INITIAL_DATA:
      case this.FINISH:
        break;
      case this.CIRCLE_NOW_DRAG_DROP:
        if (this.formState.currentCircleIndexNow === 0) {
          this.navigateToPreviousStep();
        } else {
          this.navigateToPreviousCircleInNow();
        }
        break;
      case this.CIRCLE_NOW_PICK:
        this.navigateToPreviousStep();
        break;
      case this.QUESTIONS:
        if (this.questionsComponent?.isAtStart()) {
          this.navigateToPreviousStep();
        } else {
          this.questionsComponent?.prev();
        }
        break;
      case this.CIRCLE_PAST_DRAG_DROP:
        if (this.formState.currentCircleIndexPast === 0) {
          this.navigateToPreviousStep();
        } else {
          this.navigateToPreviousCircleInPast();
        }
        break;
    }
  }

  navigateToPreviousStep(): void {
    this.formState.currentStep--;
    this.persist();
  }

  navigateToPreviousCircleInNow(): void {
    this.formState.currentCircleIndexNow--;
    this.formState.currentStep = this.QUESTIONS;
    this.formState.currentPerson = this.formState
      .circles[this.formState.currentCircleIndexNow]
      .persons
      .map(person => person.isImportant).length - 1;
    this.formState.currentQuestionGroup = this.QUESTION_GROUP_COUNT - 1;
    this.persist();
  }

  navigateToPreviousCircleInPast(): void {
    this.formState.currentCircleIndexPast--;
    this.persist();
  }

  navigateToNextStep(): void {
    this.formState.currentStep++;
    this.persist();
  }

  navigateToNextCircleInNow(): void {
    this.formState.currentCircleIndexNow++;
    this.formState.currentStep = this.CIRCLE_NOW_DRAG_DROP;
    this.formState.currentQuestionGroup = 0;
    this.formState.currentPerson = 0;
    this.persist();
  }

  navigateToNextCircleInPast(): void {
    this.formState.currentCircleIndexPast++;
    this.persist();
  }

  next(): void {
    switch (this.formState.currentStep) {
      case this.INITIAL_DATA:
        this.navigateToNextStep();
        break;
      case this.CIRCLE_NOW_DRAG_DROP:
        this.navigateToNextStep();
        break;
      case this.CIRCLE_NOW_PICK:
        this.navigateToNextStep();
        break;
      case this.QUESTIONS:
        if (this.questionsComponent?.isAtEnd()) {
          if (this.formState.currentCircleIndexNow + 1 < this.formState.circles.length) {
            this.navigateToNextCircleInNow();
          } else {
            this.navigateToNextStep();
          }
        } else {
          this.questionsComponent?.next();
        }
        break;
      case this.CIRCLE_PAST_DRAG_DROP:
        if (this.formState.currentCircleIndexPast + 1 < this.formState.circles.length) {
          this.navigateToNextCircleInPast();
        } else {
          this.navigateToNextStep();
          this.api.saveForm(this.formState).subscribe();
        }
        break;
      case this.FINISH:
        break;
    }
  }

  savePdf(): void {
    if (!this.canvasComponents) {
      return;
    }

    const canvasComponentArray = this.canvasComponents.toArray();

    const pdf = new jsPDF('landscape', 'px', 'a4', true);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const widthRatio = pdfWidth / 1280;
    const heightRatio = pdfHeight / 720;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    const canvasWidth = 1280 * ratio;
    const canvasHeight = 720 * ratio;

    const marginX = (pdfWidth - canvasWidth) / 2;
    const marginY = (pdfHeight - canvasHeight) / 2;

    const imgData = canvasComponentArray.map(canvas => canvas.toImageData());

    imgData?.forEach((data, index) => {
      if (index > 0) {
        pdf.addPage('a4', 'landscape');
      }
      const canvas = canvasComponentArray[index];
      const isPresent = canvas.viewMode === 'print_now';
      pdf.setFontSize(16);
      pdf.text(
        this.ts.translate(
          'print.title' , {
            circleDescription: canvas.circle.getDescription(),
            circlePeriod: isPresent ? canvas.circle.getPeriodNow() : canvas.circle.getPeriodPast()
          }),
        8,
        16
      );
      pdf.addImage(data, 'JPG', marginX, marginY, canvasWidth, canvasHeight);
    });

    pdf.setFontSize(12);
    pdf.text(
      this.ts.translate('print.hint', {
        date: formatDate(new Date(), 'd.M.yyyy', 'en'),
        id: (this.formState.name ?? '')
      }),
      8,
      pdfHeight - 12
    );

    pdf.save(this.ts.translate('form.printFileName'));
  }

  openTermsDialog(type: TermsDialogType): void {
    this.dialog.open(TermsDialogComponent, {data: new TermsDialogOptions(type)});
  }

  isAtEnd(): boolean {
    return this.formState.currentStep + 1 >= this.FINISH && (this.questionsComponent?.isAtEnd() ?? false);
  }

  restartWithWarning(): void {
    if (this.formState.currentStep !== this.FINISH) {
      ModalHelper.open(this.dialog, this.bottomSheet, FormRestartComponent).subscribe((result: FormRestartResult) => {
        if (result.shouldRestart) {
          this.restart();
        }
      });
    } else {
      this.restart();
    }
  }

  restart(): void {
    this.persistenceService.clearFormState();
    this.initializeFormState();
    this.nameControl.patchValue(null);
    this.tosAcceptedControl.patchValue(false);
    this.privacyAcceptedControl.patchValue(false);
  }


  getTitle(): string | undefined {
    switch (this.formState.currentStep) {
      case this.INITIAL_DATA:
        return this.ts.translate('form.titles.initialData');
      case this.CIRCLE_NOW_DRAG_DROP:
      case this.CIRCLE_NOW_PICK:
        return this.formState.circles[this.formState.currentCircleIndexNow].circle.getDescription();
      case this.CIRCLE_PAST_DRAG_DROP:
        return this.formState.circles[this.formState.currentCircleIndexPast].circle.getDescription();
      case this.QUESTIONS:
        return this.ts.translate('form.titles.questions');
    }

    return undefined;
  }

  getHint(): string | undefined {
    switch (this.formState.currentStep) {
      case this.CIRCLE_NOW_DRAG_DROP:
        return this.ts.translate(
          'form.hints.circleFill',
          {timeframe: this.formState.circles[this.formState.currentCircleIndexNow].circle.getPeriodNow()}
        );
      case this.CIRCLE_NOW_PICK:
        return this.ts.translate('form.hints.circlePick');
      case this.QUESTIONS:
        return this.ts.translate('form.hints.question', {
          name: this.questionsComponent?.currentPerson?.person.getFullName() ?? '',
          current: (this.questionsComponent?.currentPersonIndex ?? 0) + 1,
          amount: this.questionsComponent?.importantPersons.length ?? ''
        });
      case this.CIRCLE_PAST_DRAG_DROP:
        return this.ts.translate(
          'form.hints.circleFillPast',
          {timeframe: this.formState.circles[this.formState.currentCircleIndexNow].circle.getPeriodPast()}
        );
    }
    return undefined;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
