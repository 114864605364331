/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.18.1
// source: api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as api_pb from './api_pb'; // proto import: "api.proto"


export class SurveyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetApplicationState = new grpcWeb.MethodDescriptor(
    '/api.SurveyService/GetApplicationState',
    grpcWeb.MethodType.UNARY,
    api_pb.Empty,
    api_pb.ApplicationState,
    (request: api_pb.Empty) => {
      return request.serializeBinary();
    },
    api_pb.ApplicationState.deserializeBinary
  );

  getApplicationState(
    request: api_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.ApplicationState>;

  getApplicationState(
    request: api_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.ApplicationState) => void): grpcWeb.ClientReadableStream<api_pb.ApplicationState>;

  getApplicationState(
    request: api_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.ApplicationState) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.SurveyService/GetApplicationState',
        request,
        metadata || {},
        this.methodDescriptorGetApplicationState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.SurveyService/GetApplicationState',
    request,
    metadata || {},
    this.methodDescriptorGetApplicationState);
  }

  methodDescriptorSaveSurvey = new grpcWeb.MethodDescriptor(
    '/api.SurveyService/SaveSurvey',
    grpcWeb.MethodType.UNARY,
    api_pb.Survey,
    api_pb.Empty,
    (request: api_pb.Survey) => {
      return request.serializeBinary();
    },
    api_pb.Empty.deserializeBinary
  );

  saveSurvey(
    request: api_pb.Survey,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.Empty>;

  saveSurvey(
    request: api_pb.Survey,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void): grpcWeb.ClientReadableStream<api_pb.Empty>;

  saveSurvey(
    request: api_pb.Survey,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.SurveyService/SaveSurvey',
        request,
        metadata || {},
        this.methodDescriptorSaveSurvey,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.SurveyService/SaveSurvey',
    request,
    metadata || {},
    this.methodDescriptorSaveSurvey);
  }

  methodDescriptorGetQuestions = new grpcWeb.MethodDescriptor(
    '/api.SurveyService/GetQuestions',
    grpcWeb.MethodType.UNARY,
    api_pb.LanguageRequest,
    api_pb.GetQuestionsResponse,
    (request: api_pb.LanguageRequest) => {
      return request.serializeBinary();
    },
    api_pb.GetQuestionsResponse.deserializeBinary
  );

  getQuestions(
    request: api_pb.LanguageRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetQuestionsResponse>;

  getQuestions(
    request: api_pb.LanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetQuestionsResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetQuestionsResponse>;

  getQuestions(
    request: api_pb.LanguageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetQuestionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.SurveyService/GetQuestions',
        request,
        metadata || {},
        this.methodDescriptorGetQuestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.SurveyService/GetQuestions',
    request,
    metadata || {},
    this.methodDescriptorGetQuestions);
  }

  methodDescriptorGetCircle = new grpcWeb.MethodDescriptor(
    '/api.SurveyService/GetCircle',
    grpcWeb.MethodType.UNARY,
    api_pb.IdRequest,
    api_pb.GetCircleResponse,
    (request: api_pb.IdRequest) => {
      return request.serializeBinary();
    },
    api_pb.GetCircleResponse.deserializeBinary
  );

  getCircle(
    request: api_pb.IdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetCircleResponse>;

  getCircle(
    request: api_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetCircleResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetCircleResponse>;

  getCircle(
    request: api_pb.IdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetCircleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.SurveyService/GetCircle',
        request,
        metadata || {},
        this.methodDescriptorGetCircle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.SurveyService/GetCircle',
    request,
    metadata || {},
    this.methodDescriptorGetCircle);
  }

  methodDescriptorGetCircles = new grpcWeb.MethodDescriptor(
    '/api.SurveyService/GetCircles',
    grpcWeb.MethodType.UNARY,
    api_pb.LanguageRequest,
    api_pb.GetCirclesResponse,
    (request: api_pb.LanguageRequest) => {
      return request.serializeBinary();
    },
    api_pb.GetCirclesResponse.deserializeBinary
  );

  getCircles(
    request: api_pb.LanguageRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetCirclesResponse>;

  getCircles(
    request: api_pb.LanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetCirclesResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetCirclesResponse>;

  getCircles(
    request: api_pb.LanguageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetCirclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.SurveyService/GetCircles',
        request,
        metadata || {},
        this.methodDescriptorGetCircles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.SurveyService/GetCircles',
    request,
    metadata || {},
    this.methodDescriptorGetCircles);
  }

}

export class AdminServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorLogin = new grpcWeb.MethodDescriptor(
    '/api.AdminService/Login',
    grpcWeb.MethodType.UNARY,
    api_pb.LoginRequest,
    api_pb.Empty,
    (request: api_pb.LoginRequest) => {
      return request.serializeBinary();
    },
    api_pb.Empty.deserializeBinary
  );

  login(
    request: api_pb.LoginRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.Empty>;

  login(
    request: api_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void): grpcWeb.ClientReadableStream<api_pb.Empty>;

  login(
    request: api_pb.LoginRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AdminService/Login',
        request,
        metadata || {},
        this.methodDescriptorLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AdminService/Login',
    request,
    metadata || {},
    this.methodDescriptorLogin);
  }

  methodDescriptorLogout = new grpcWeb.MethodDescriptor(
    '/api.AdminService/Logout',
    grpcWeb.MethodType.UNARY,
    api_pb.Empty,
    api_pb.Empty,
    (request: api_pb.Empty) => {
      return request.serializeBinary();
    },
    api_pb.Empty.deserializeBinary
  );

  logout(
    request: api_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.Empty>;

  logout(
    request: api_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void): grpcWeb.ClientReadableStream<api_pb.Empty>;

  logout(
    request: api_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AdminService/Logout',
        request,
        metadata || {},
        this.methodDescriptorLogout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AdminService/Logout',
    request,
    metadata || {},
    this.methodDescriptorLogout);
  }

  methodDescriptorState = new grpcWeb.MethodDescriptor(
    '/api.AdminService/State',
    grpcWeb.MethodType.UNARY,
    api_pb.Empty,
    api_pb.Empty,
    (request: api_pb.Empty) => {
      return request.serializeBinary();
    },
    api_pb.Empty.deserializeBinary
  );

  state(
    request: api_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.Empty>;

  state(
    request: api_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void): grpcWeb.ClientReadableStream<api_pb.Empty>;

  state(
    request: api_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AdminService/State',
        request,
        metadata || {},
        this.methodDescriptorState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AdminService/State',
    request,
    metadata || {},
    this.methodDescriptorState);
  }

  methodDescriptorSearchSurveys = new grpcWeb.MethodDescriptor(
    '/api.AdminService/SearchSurveys',
    grpcWeb.MethodType.UNARY,
    api_pb.SearchSurveysRequest,
    api_pb.SearchSurveysResponse,
    (request: api_pb.SearchSurveysRequest) => {
      return request.serializeBinary();
    },
    api_pb.SearchSurveysResponse.deserializeBinary
  );

  searchSurveys(
    request: api_pb.SearchSurveysRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.SearchSurveysResponse>;

  searchSurveys(
    request: api_pb.SearchSurveysRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.SearchSurveysResponse) => void): grpcWeb.ClientReadableStream<api_pb.SearchSurveysResponse>;

  searchSurveys(
    request: api_pb.SearchSurveysRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.SearchSurveysResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AdminService/SearchSurveys',
        request,
        metadata || {},
        this.methodDescriptorSearchSurveys,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AdminService/SearchSurveys',
    request,
    metadata || {},
    this.methodDescriptorSearchSurveys);
  }

  methodDescriptorExportSurveys = new grpcWeb.MethodDescriptor(
    '/api.AdminService/ExportSurveys',
    grpcWeb.MethodType.UNARY,
    api_pb.ExportSurveysRequest,
    api_pb.ExportSurveysResponse,
    (request: api_pb.ExportSurveysRequest) => {
      return request.serializeBinary();
    },
    api_pb.ExportSurveysResponse.deserializeBinary
  );

  exportSurveys(
    request: api_pb.ExportSurveysRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.ExportSurveysResponse>;

  exportSurveys(
    request: api_pb.ExportSurveysRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.ExportSurveysResponse) => void): grpcWeb.ClientReadableStream<api_pb.ExportSurveysResponse>;

  exportSurveys(
    request: api_pb.ExportSurveysRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.ExportSurveysResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AdminService/ExportSurveys',
        request,
        metadata || {},
        this.methodDescriptorExportSurveys,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AdminService/ExportSurveys',
    request,
    metadata || {},
    this.methodDescriptorExportSurveys);
  }

  methodDescriptorGetAdminCircles = new grpcWeb.MethodDescriptor(
    '/api.AdminService/GetAdminCircles',
    grpcWeb.MethodType.UNARY,
    api_pb.Empty,
    api_pb.GetAdminCirclesResponse,
    (request: api_pb.Empty) => {
      return request.serializeBinary();
    },
    api_pb.GetAdminCirclesResponse.deserializeBinary
  );

  getAdminCircles(
    request: api_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetAdminCirclesResponse>;

  getAdminCircles(
    request: api_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetAdminCirclesResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetAdminCirclesResponse>;

  getAdminCircles(
    request: api_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetAdminCirclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AdminService/GetAdminCircles',
        request,
        metadata || {},
        this.methodDescriptorGetAdminCircles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AdminService/GetAdminCircles',
    request,
    metadata || {},
    this.methodDescriptorGetAdminCircles);
  }

  methodDescriptorUpdateCircle = new grpcWeb.MethodDescriptor(
    '/api.AdminService/UpdateCircle',
    grpcWeb.MethodType.UNARY,
    api_pb.AdminCircle,
    api_pb.AdminCircle,
    (request: api_pb.AdminCircle) => {
      return request.serializeBinary();
    },
    api_pb.AdminCircle.deserializeBinary
  );

  updateCircle(
    request: api_pb.AdminCircle,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.AdminCircle>;

  updateCircle(
    request: api_pb.AdminCircle,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.AdminCircle) => void): grpcWeb.ClientReadableStream<api_pb.AdminCircle>;

  updateCircle(
    request: api_pb.AdminCircle,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.AdminCircle) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AdminService/UpdateCircle',
        request,
        metadata || {},
        this.methodDescriptorUpdateCircle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AdminService/UpdateCircle',
    request,
    metadata || {},
    this.methodDescriptorUpdateCircle);
  }

  methodDescriptorDeleteCircle = new grpcWeb.MethodDescriptor(
    '/api.AdminService/DeleteCircle',
    grpcWeb.MethodType.UNARY,
    api_pb.IdRequest,
    api_pb.Empty,
    (request: api_pb.IdRequest) => {
      return request.serializeBinary();
    },
    api_pb.Empty.deserializeBinary
  );

  deleteCircle(
    request: api_pb.IdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.Empty>;

  deleteCircle(
    request: api_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void): grpcWeb.ClientReadableStream<api_pb.Empty>;

  deleteCircle(
    request: api_pb.IdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.AdminService/DeleteCircle',
        request,
        metadata || {},
        this.methodDescriptorDeleteCircle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.AdminService/DeleteCircle',
    request,
    metadata || {},
    this.methodDescriptorDeleteCircle);
  }

}

