import {Circle} from '../../grpc/api_pb';
import {BinaryWriter} from 'google-protobuf';
import {Base64Converter} from '../util/base-64-converter';
import {PersonWrapper} from './person-wrapper';

export class CircleWrapper {

  constructor(id: number, circle: Circle) {
    this.id = id;
    this.circle = circle;
  }
  id: number;
  circle: Circle;
  persons: PersonWrapper[] = [];

  static fromJson(json: any): CircleWrapper {
    const circle = new CircleWrapper(json.id, Circle.deserializeBinary(Base64Converter.toUint8Array(json.circle)));
    circle.persons = json.persons.map((personJson: any) => {
      const person = new PersonWrapper();
      person.fromJson(personJson);
      return person;
    });
    return circle;
  }

  toJson(): any {
    const circleWriter = new BinaryWriter();
    Circle.serializeBinaryToWriter(this.circle, circleWriter);
    return {
      id: this.id,
      circle: circleWriter.getResultBase64String(),
      persons: this.persons.map(person => person.toJson())
    };
  }
}
