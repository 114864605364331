import {CircleWrapper} from './circle-wrapper';

export class FormState {
  currentCircleIndexNow = 0;
  currentCircleIndexPast = 0;
  currentStep = 0;
  currentPerson = 0;
  currentQuestionGroup = 0;
  name?: string;
  start?: Date;
  tosAccepted = false;
  circles: CircleWrapper[] = [];
  privacyAccepted = false;


  toJson(): any {
    return {
      currentCircleIndexNow: this.currentCircleIndexNow,
      currentCircleIndexPast: this.currentCircleIndexPast,
      name: this.name,
      start: this.start?.toJSON(),
      circles: this.circles?.map(circle => circle.toJson()),
      currentStep: this.currentStep,
      currentPerson: this.currentPerson,
      tosAccepted: this.tosAccepted,
      privacyAccepted: this.privacyAccepted,
      currentQuestionGroup: this.currentQuestionGroup
    };
  }

  fromJson(json: any): void {
    this.currentCircleIndexNow = json.currentCircleIndexNow;
    this.currentCircleIndexPast = json.currentCircleIndexPast;
    this.name = json.name;
    this.start = json.start !== undefined ? new Date(json.start) : undefined;
    this.circles = json.circles.map((circleJson: any) => CircleWrapper.fromJson(circleJson));
    this.currentStep = json.currentStep;
    this.currentPerson = json.currentPerson;
    this.currentQuestionGroup = json.currentQuestionGroup;
    this.tosAccepted = json.tosAccepted;
    this.privacyAccepted = json.privacyAccepted;
  }
}
