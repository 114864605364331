// source: api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.api.AdminCircle', null, global);
goog.exportSymbol('proto.api.AdminCircleTitle', null, global);
goog.exportSymbol('proto.api.Answer', null, global);
goog.exportSymbol('proto.api.ApplicationState', null, global);
goog.exportSymbol('proto.api.Circle', null, global);
goog.exportSymbol('proto.api.ContactFrequency', null, global);
goog.exportSymbol('proto.api.Empty', null, global);
goog.exportSymbol('proto.api.ExportSurveysRequest', null, global);
goog.exportSymbol('proto.api.ExportSurveysResponse', null, global);
goog.exportSymbol('proto.api.GetAdminCirclesResponse', null, global);
goog.exportSymbol('proto.api.GetCircleResponse', null, global);
goog.exportSymbol('proto.api.GetCirclesResponse', null, global);
goog.exportSymbol('proto.api.GetQuestionsResponse', null, global);
goog.exportSymbol('proto.api.IdRequest', null, global);
goog.exportSymbol('proto.api.LanguageRequest', null, global);
goog.exportSymbol('proto.api.LoginRequest', null, global);
goog.exportSymbol('proto.api.Question', null, global);
goog.exportSymbol('proto.api.QuestionGroup', null, global);
goog.exportSymbol('proto.api.QuestionType', null, global);
goog.exportSymbol('proto.api.Relationship', null, global);
goog.exportSymbol('proto.api.SearchSurveysRequest', null, global);
goog.exportSymbol('proto.api.SearchSurveysResponse', null, global);
goog.exportSymbol('proto.api.Survey', null, global);
goog.exportSymbol('proto.api.SurveyCircle', null, global);
goog.exportSymbol('proto.api.SurveyPerson', null, global);
goog.exportSymbol('proto.api.SurveyView', null, global);
goog.exportSymbol('proto.api.YesNoMaybe', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Empty.displayName = 'proto.api.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ApplicationState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ApplicationState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ApplicationState.displayName = 'proto.api.ApplicationState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IdRequest.displayName = 'proto.api.IdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.LanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.LanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.LanguageRequest.displayName = 'proto.api.LanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchSurveysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SearchSurveysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchSurveysRequest.displayName = 'proto.api.SearchSurveysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchSurveysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.SearchSurveysResponse.repeatedFields_, null);
};
goog.inherits(proto.api.SearchSurveysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchSurveysResponse.displayName = 'proto.api.SearchSurveysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ExportSurveysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ExportSurveysRequest.repeatedFields_, null);
};
goog.inherits(proto.api.ExportSurveysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ExportSurveysRequest.displayName = 'proto.api.ExportSurveysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ExportSurveysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ExportSurveysResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ExportSurveysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ExportSurveysResponse.displayName = 'proto.api.ExportSurveysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetQuestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetQuestionsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetQuestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetQuestionsResponse.displayName = 'proto.api.GetQuestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetCircleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetCircleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetCircleResponse.displayName = 'proto.api.GetCircleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetCirclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetCirclesResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetCirclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetCirclesResponse.displayName = 'proto.api.GetCirclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetAdminCirclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetAdminCirclesResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetAdminCirclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetAdminCirclesResponse.displayName = 'proto.api.GetAdminCirclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.LoginRequest.displayName = 'proto.api.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SurveyView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SurveyView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SurveyView.displayName = 'proto.api.SurveyView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Survey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.Survey.repeatedFields_, null);
};
goog.inherits(proto.api.Survey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Survey.displayName = 'proto.api.Survey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SurveyCircle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.SurveyCircle.repeatedFields_, null);
};
goog.inherits(proto.api.SurveyCircle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SurveyCircle.displayName = 'proto.api.SurveyCircle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SurveyPerson = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.SurveyPerson.repeatedFields_, null);
};
goog.inherits(proto.api.SurveyPerson, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SurveyPerson.displayName = 'proto.api.SurveyPerson';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.QuestionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.QuestionGroup.repeatedFields_, null);
};
goog.inherits(proto.api.QuestionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.QuestionGroup.displayName = 'proto.api.QuestionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Question = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Question, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Question.displayName = 'proto.api.Question';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Answer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Answer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Answer.displayName = 'proto.api.Answer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Circle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.Circle.repeatedFields_, null);
};
goog.inherits(proto.api.Circle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Circle.displayName = 'proto.api.Circle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AdminCircle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.AdminCircle.repeatedFields_, null);
};
goog.inherits(proto.api.AdminCircle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AdminCircle.displayName = 'proto.api.AdminCircle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AdminCircleTitle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AdminCircleTitle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AdminCircleTitle.displayName = 'proto.api.AdminCircleTitle';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Empty}
 */
proto.api.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Empty;
  return proto.api.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Empty}
 */
proto.api.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ApplicationState.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ApplicationState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ApplicationState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ApplicationState.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ApplicationState}
 */
proto.api.ApplicationState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ApplicationState;
  return proto.api.ApplicationState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ApplicationState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ApplicationState}
 */
proto.api.ApplicationState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ApplicationState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ApplicationState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ApplicationState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ApplicationState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.api.ApplicationState.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ApplicationState} returns this
 */
proto.api.ApplicationState.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lang: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IdRequest}
 */
proto.api.IdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IdRequest;
  return proto.api.IdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IdRequest}
 */
proto.api.IdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLang();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.IdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.IdRequest} returns this
 */
proto.api.IdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string lang = 2;
 * @return {string}
 */
proto.api.IdRequest.prototype.getLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IdRequest} returns this
 */
proto.api.IdRequest.prototype.setLang = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.LanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.LanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.LanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lang: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.LanguageRequest}
 */
proto.api.LanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.LanguageRequest;
  return proto.api.LanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.LanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.LanguageRequest}
 */
proto.api.LanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.LanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.LanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.LanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLang();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string lang = 1;
 * @return {string}
 */
proto.api.LanguageRequest.prototype.getLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LanguageRequest} returns this
 */
proto.api.LanguageRequest.prototype.setLang = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchSurveysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchSurveysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchSurveysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchSurveysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    abbreviation: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchSurveysRequest}
 */
proto.api.SearchSurveysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchSurveysRequest;
  return proto.api.SearchSurveysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchSurveysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchSurveysRequest}
 */
proto.api.SearchSurveysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbreviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchSurveysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchSurveysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchSurveysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchSurveysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string abbreviation = 1;
 * @return {string}
 */
proto.api.SearchSurveysRequest.prototype.getAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchSurveysRequest} returns this
 */
proto.api.SearchSurveysRequest.prototype.setAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.SearchSurveysResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchSurveysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchSurveysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchSurveysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchSurveysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    surveysList: jspb.Message.toObjectList(msg.getSurveysList(),
    proto.api.SurveyView.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchSurveysResponse}
 */
proto.api.SearchSurveysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchSurveysResponse;
  return proto.api.SearchSurveysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchSurveysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchSurveysResponse}
 */
proto.api.SearchSurveysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.SurveyView;
      reader.readMessage(value,proto.api.SurveyView.deserializeBinaryFromReader);
      msg.addSurveys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchSurveysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchSurveysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchSurveysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchSurveysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurveysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.SurveyView.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SurveyView surveys = 1;
 * @return {!Array<!proto.api.SurveyView>}
 */
proto.api.SearchSurveysResponse.prototype.getSurveysList = function() {
  return /** @type{!Array<!proto.api.SurveyView>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.SurveyView, 1));
};


/**
 * @param {!Array<!proto.api.SurveyView>} value
 * @return {!proto.api.SearchSurveysResponse} returns this
*/
proto.api.SearchSurveysResponse.prototype.setSurveysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.SurveyView=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.SurveyView}
 */
proto.api.SearchSurveysResponse.prototype.addSurveys = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.SurveyView, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.SearchSurveysResponse} returns this
 */
proto.api.SearchSurveysResponse.prototype.clearSurveysList = function() {
  return this.setSurveysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ExportSurveysRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ExportSurveysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ExportSurveysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ExportSurveysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ExportSurveysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ExportSurveysRequest}
 */
proto.api.ExportSurveysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ExportSurveysRequest;
  return proto.api.ExportSurveysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ExportSurveysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ExportSurveysRequest}
 */
proto.api.ExportSurveysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ExportSurveysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ExportSurveysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ExportSurveysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ExportSurveysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.api.ExportSurveysRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.api.ExportSurveysRequest} returns this
 */
proto.api.ExportSurveysRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.api.ExportSurveysRequest} returns this
 */
proto.api.ExportSurveysRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ExportSurveysRequest} returns this
 */
proto.api.ExportSurveysRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ExportSurveysResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ExportSurveysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ExportSurveysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ExportSurveysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ExportSurveysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsList: msg.getRecordsList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ExportSurveysResponse}
 */
proto.api.ExportSurveysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ExportSurveysResponse;
  return proto.api.ExportSurveysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ExportSurveysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ExportSurveysResponse}
 */
proto.api.ExportSurveysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ExportSurveysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ExportSurveysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ExportSurveysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ExportSurveysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
};


/**
 * repeated bytes records = 1;
 * @return {!Array<string>}
 */
proto.api.ExportSurveysResponse.prototype.getRecordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * repeated bytes records = 1;
 * This is a type-conversion wrapper around `getRecordsList()`
 * @return {!Array<string>}
 */
proto.api.ExportSurveysResponse.prototype.getRecordsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getRecordsList()));
};


/**
 * repeated bytes records = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRecordsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.api.ExportSurveysResponse.prototype.getRecordsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getRecordsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.api.ExportSurveysResponse} returns this
 */
proto.api.ExportSurveysResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.api.ExportSurveysResponse} returns this
 */
proto.api.ExportSurveysResponse.prototype.addRecords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ExportSurveysResponse} returns this
 */
proto.api.ExportSurveysResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetQuestionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetQuestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetQuestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetQuestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetQuestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.api.QuestionGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetQuestionsResponse}
 */
proto.api.GetQuestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetQuestionsResponse;
  return proto.api.GetQuestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetQuestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetQuestionsResponse}
 */
proto.api.GetQuestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.QuestionGroup;
      reader.readMessage(value,proto.api.QuestionGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetQuestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetQuestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetQuestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetQuestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.QuestionGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QuestionGroup groups = 1;
 * @return {!Array<!proto.api.QuestionGroup>}
 */
proto.api.GetQuestionsResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.api.QuestionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.QuestionGroup, 1));
};


/**
 * @param {!Array<!proto.api.QuestionGroup>} value
 * @return {!proto.api.GetQuestionsResponse} returns this
*/
proto.api.GetQuestionsResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.QuestionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.QuestionGroup}
 */
proto.api.GetQuestionsResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.QuestionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetQuestionsResponse} returns this
 */
proto.api.GetQuestionsResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetCircleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetCircleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetCircleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetCircleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    circle: (f = msg.getCircle()) && proto.api.Circle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetCircleResponse}
 */
proto.api.GetCircleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetCircleResponse;
  return proto.api.GetCircleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetCircleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetCircleResponse}
 */
proto.api.GetCircleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Circle;
      reader.readMessage(value,proto.api.Circle.deserializeBinaryFromReader);
      msg.setCircle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetCircleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetCircleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetCircleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetCircleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCircle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Circle.serializeBinaryToWriter
    );
  }
};


/**
 * optional Circle circle = 1;
 * @return {?proto.api.Circle}
 */
proto.api.GetCircleResponse.prototype.getCircle = function() {
  return /** @type{?proto.api.Circle} */ (
    jspb.Message.getWrapperField(this, proto.api.Circle, 1));
};


/**
 * @param {?proto.api.Circle|undefined} value
 * @return {!proto.api.GetCircleResponse} returns this
*/
proto.api.GetCircleResponse.prototype.setCircle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetCircleResponse} returns this
 */
proto.api.GetCircleResponse.prototype.clearCircle = function() {
  return this.setCircle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetCircleResponse.prototype.hasCircle = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetCirclesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetCirclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetCirclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetCirclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetCirclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    circlesList: jspb.Message.toObjectList(msg.getCirclesList(),
    proto.api.Circle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetCirclesResponse}
 */
proto.api.GetCirclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetCirclesResponse;
  return proto.api.GetCirclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetCirclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetCirclesResponse}
 */
proto.api.GetCirclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Circle;
      reader.readMessage(value,proto.api.Circle.deserializeBinaryFromReader);
      msg.addCircles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetCirclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetCirclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetCirclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetCirclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.Circle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Circle circles = 1;
 * @return {!Array<!proto.api.Circle>}
 */
proto.api.GetCirclesResponse.prototype.getCirclesList = function() {
  return /** @type{!Array<!proto.api.Circle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Circle, 1));
};


/**
 * @param {!Array<!proto.api.Circle>} value
 * @return {!proto.api.GetCirclesResponse} returns this
*/
proto.api.GetCirclesResponse.prototype.setCirclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Circle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Circle}
 */
proto.api.GetCirclesResponse.prototype.addCircles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Circle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetCirclesResponse} returns this
 */
proto.api.GetCirclesResponse.prototype.clearCirclesList = function() {
  return this.setCirclesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetAdminCirclesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetAdminCirclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetAdminCirclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetAdminCirclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAdminCirclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    circlesList: jspb.Message.toObjectList(msg.getCirclesList(),
    proto.api.AdminCircle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetAdminCirclesResponse}
 */
proto.api.GetAdminCirclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetAdminCirclesResponse;
  return proto.api.GetAdminCirclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetAdminCirclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetAdminCirclesResponse}
 */
proto.api.GetAdminCirclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AdminCircle;
      reader.readMessage(value,proto.api.AdminCircle.deserializeBinaryFromReader);
      msg.addCircles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetAdminCirclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetAdminCirclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetAdminCirclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAdminCirclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.AdminCircle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdminCircle circles = 1;
 * @return {!Array<!proto.api.AdminCircle>}
 */
proto.api.GetAdminCirclesResponse.prototype.getCirclesList = function() {
  return /** @type{!Array<!proto.api.AdminCircle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AdminCircle, 1));
};


/**
 * @param {!Array<!proto.api.AdminCircle>} value
 * @return {!proto.api.GetAdminCirclesResponse} returns this
*/
proto.api.GetAdminCirclesResponse.prototype.setCirclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.AdminCircle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AdminCircle}
 */
proto.api.GetAdminCirclesResponse.prototype.addCircles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.AdminCircle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetAdminCirclesResponse} returns this
 */
proto.api.GetAdminCirclesResponse.prototype.clearCirclesList = function() {
  return this.setCirclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.LoginRequest}
 */
proto.api.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.LoginRequest;
  return proto.api.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.LoginRequest}
 */
proto.api.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.api.LoginRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LoginRequest} returns this
 */
proto.api.LoginRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.api.LoginRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LoginRequest} returns this
 */
proto.api.LoginRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SurveyView.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SurveyView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SurveyView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SurveyView.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SurveyView}
 */
proto.api.SurveyView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SurveyView;
  return proto.api.SurveyView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SurveyView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SurveyView}
 */
proto.api.SurveyView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SurveyView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SurveyView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SurveyView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SurveyView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.SurveyView.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyView} returns this
 */
proto.api.SurveyView.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.api.SurveyView.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyView} returns this
 */
proto.api.SurveyView.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.Survey.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Survey.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Survey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Survey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Survey.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    abbreviation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 9, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    termsOfUseAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    privacyPolicyAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    circlesList: jspb.Message.toObjectList(msg.getCirclesList(),
    proto.api.SurveyCircle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Survey}
 */
proto.api.Survey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Survey;
  return proto.api.Survey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Survey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Survey}
 */
proto.api.Survey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbreviation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTermsOfUseAccepted(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivacyPolicyAccepted(value);
      break;
    case 16:
      var value = new proto.api.SurveyCircle;
      reader.readMessage(value,proto.api.SurveyCircle.deserializeBinaryFromReader);
      msg.addCircles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Survey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Survey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Survey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Survey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getTermsOfUseAccepted();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getPrivacyPolicyAccepted();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getCirclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.api.SurveyCircle.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.Survey.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string abbreviation = 2;
 * @return {string}
 */
proto.api.Survey.prototype.getAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 5;
 * @return {string}
 */
proto.api.Survey.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string full_name = 6;
 * @return {string}
 */
proto.api.Survey.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.api.Survey.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string comment = 9;
 * @return {string}
 */
proto.api.Survey.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 start_time = 11;
 * @return {number}
 */
proto.api.Survey.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 end_time = 12;
 * @return {number}
 */
proto.api.Survey.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool terms_of_use_accepted = 13;
 * @return {boolean}
 */
proto.api.Survey.prototype.getTermsOfUseAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setTermsOfUseAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool privacy_policy_accepted = 14;
 * @return {boolean}
 */
proto.api.Survey.prototype.getPrivacyPolicyAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.setPrivacyPolicyAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * repeated SurveyCircle circles = 16;
 * @return {!Array<!proto.api.SurveyCircle>}
 */
proto.api.Survey.prototype.getCirclesList = function() {
  return /** @type{!Array<!proto.api.SurveyCircle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.SurveyCircle, 16));
};


/**
 * @param {!Array<!proto.api.SurveyCircle>} value
 * @return {!proto.api.Survey} returns this
*/
proto.api.Survey.prototype.setCirclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.api.SurveyCircle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.SurveyCircle}
 */
proto.api.Survey.prototype.addCircles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.api.SurveyCircle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Survey} returns this
 */
proto.api.Survey.prototype.clearCirclesList = function() {
  return this.setCirclesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.SurveyCircle.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SurveyCircle.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SurveyCircle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SurveyCircle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SurveyCircle.toObject = function(includeInstance, msg) {
  var f, obj = {
    circleId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    personsList: jspb.Message.toObjectList(msg.getPersonsList(),
    proto.api.SurveyPerson.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SurveyCircle}
 */
proto.api.SurveyCircle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SurveyCircle;
  return proto.api.SurveyCircle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SurveyCircle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SurveyCircle}
 */
proto.api.SurveyCircle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCircleId(value);
      break;
    case 2:
      var value = new proto.api.SurveyPerson;
      reader.readMessage(value,proto.api.SurveyPerson.deserializeBinaryFromReader);
      msg.addPersons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SurveyCircle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SurveyCircle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SurveyCircle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SurveyCircle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCircleId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPersonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.SurveyPerson.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 circle_id = 1;
 * @return {number}
 */
proto.api.SurveyCircle.prototype.getCircleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyCircle} returns this
 */
proto.api.SurveyCircle.prototype.setCircleId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SurveyPerson persons = 2;
 * @return {!Array<!proto.api.SurveyPerson>}
 */
proto.api.SurveyCircle.prototype.getPersonsList = function() {
  return /** @type{!Array<!proto.api.SurveyPerson>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.SurveyPerson, 2));
};


/**
 * @param {!Array<!proto.api.SurveyPerson>} value
 * @return {!proto.api.SurveyCircle} returns this
*/
proto.api.SurveyCircle.prototype.setPersonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.SurveyPerson=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.SurveyPerson}
 */
proto.api.SurveyCircle.prototype.addPersons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.SurveyPerson, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.SurveyCircle} returns this
 */
proto.api.SurveyCircle.prototype.clearPersonsList = function() {
  return this.setPersonsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.SurveyPerson.repeatedFields_ = [24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SurveyPerson.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SurveyPerson.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SurveyPerson} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SurveyPerson.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    abbreviation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isGroup: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isImportant: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    groupSize: jspb.Message.getFieldWithDefault(msg, 7, 0),
    age: jspb.Message.getFieldWithDefault(msg, 8, 0),
    minAge: jspb.Message.getFieldWithDefault(msg, 9, 0),
    maxAge: jspb.Message.getFieldWithDefault(msg, 10, 0),
    circleNr: jspb.Message.getFieldWithDefault(msg, 11, 0),
    circleAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    knownSinceMonths: jspb.Message.getFieldWithDefault(msg, 13, 0),
    relationship: jspb.Message.getFieldWithDefault(msg, 14, 0),
    otherRelationship: jspb.Message.getFieldWithDefault(msg, 15, ""),
    contactFrequency: jspb.Message.getFieldWithDefault(msg, 16, 0),
    isQueer: jspb.Message.getFieldWithDefault(msg, 17, 0),
    knowsAboutTrans: jspb.Message.getFieldWithDefault(msg, 18, 0),
    wouldChangeOpinionIfNotTrans: jspb.Message.getFieldWithDefault(msg, 19, 0),
    namePast: jspb.Message.getFieldWithDefault(msg, 20, ""),
    isGroupPast: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    isInPresent: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    isInPast: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.api.Answer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SurveyPerson}
 */
proto.api.SurveyPerson.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SurveyPerson;
  return proto.api.SurveyPerson.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SurveyPerson} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SurveyPerson}
 */
proto.api.SurveyPerson.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbreviation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGroup(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImportant(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupSize(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAge(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinAge(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxAge(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCircleNr(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCircleAngle(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKnownSinceMonths(value);
      break;
    case 14:
      var value = /** @type {!proto.api.Relationship} */ (reader.readEnum());
      msg.setRelationship(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherRelationship(value);
      break;
    case 16:
      var value = /** @type {!proto.api.ContactFrequency} */ (reader.readEnum());
      msg.setContactFrequency(value);
      break;
    case 17:
      var value = /** @type {!proto.api.YesNoMaybe} */ (reader.readEnum());
      msg.setIsQueer(value);
      break;
    case 18:
      var value = /** @type {!proto.api.YesNoMaybe} */ (reader.readEnum());
      msg.setKnowsAboutTrans(value);
      break;
    case 19:
      var value = /** @type {!proto.api.YesNoMaybe} */ (reader.readEnum());
      msg.setWouldChangeOpinionIfNotTrans(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamePast(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGroupPast(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInPresent(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInPast(value);
      break;
    case 24:
      var value = new proto.api.Answer;
      reader.readMessage(value,proto.api.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SurveyPerson.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SurveyPerson.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SurveyPerson} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SurveyPerson.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsGroup();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsImportant();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getGroupSize();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMinAge();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getMaxAge();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCircleNr();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCircleAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getKnownSinceMonths();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getRelationship();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getOtherRelationship();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getContactFrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getIsQueer();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getKnowsAboutTrans();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getWouldChangeOpinionIfNotTrans();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getNamePast();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIsGroupPast();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getIsInPresent();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getIsInPast();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.api.Answer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.SurveyPerson.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string abbreviation = 2;
 * @return {string}
 */
proto.api.SurveyPerson.prototype.getAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.api.SurveyPerson.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string gender = 4;
 * @return {string}
 */
proto.api.SurveyPerson.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_group = 5;
 * @return {boolean}
 */
proto.api.SurveyPerson.prototype.getIsGroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setIsGroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_important = 6;
 * @return {boolean}
 */
proto.api.SurveyPerson.prototype.getIsImportant = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setIsImportant = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 group_size = 7;
 * @return {number}
 */
proto.api.SurveyPerson.prototype.getGroupSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setGroupSize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 age = 8;
 * @return {number}
 */
proto.api.SurveyPerson.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setAge = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 min_age = 9;
 * @return {number}
 */
proto.api.SurveyPerson.prototype.getMinAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setMinAge = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 max_age = 10;
 * @return {number}
 */
proto.api.SurveyPerson.prototype.getMaxAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setMaxAge = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 circle_nr = 11;
 * @return {number}
 */
proto.api.SurveyPerson.prototype.getCircleNr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setCircleNr = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional float circle_angle = 12;
 * @return {number}
 */
proto.api.SurveyPerson.prototype.getCircleAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setCircleAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int32 known_since_months = 13;
 * @return {number}
 */
proto.api.SurveyPerson.prototype.getKnownSinceMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setKnownSinceMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional Relationship relationship = 14;
 * @return {!proto.api.Relationship}
 */
proto.api.SurveyPerson.prototype.getRelationship = function() {
  return /** @type {!proto.api.Relationship} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.api.Relationship} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setRelationship = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string other_relationship = 15;
 * @return {string}
 */
proto.api.SurveyPerson.prototype.getOtherRelationship = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setOtherRelationship = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional ContactFrequency contact_frequency = 16;
 * @return {!proto.api.ContactFrequency}
 */
proto.api.SurveyPerson.prototype.getContactFrequency = function() {
  return /** @type {!proto.api.ContactFrequency} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.api.ContactFrequency} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setContactFrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional YesNoMaybe is_queer = 17;
 * @return {!proto.api.YesNoMaybe}
 */
proto.api.SurveyPerson.prototype.getIsQueer = function() {
  return /** @type {!proto.api.YesNoMaybe} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.api.YesNoMaybe} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setIsQueer = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional YesNoMaybe knows_about_trans = 18;
 * @return {!proto.api.YesNoMaybe}
 */
proto.api.SurveyPerson.prototype.getKnowsAboutTrans = function() {
  return /** @type {!proto.api.YesNoMaybe} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.api.YesNoMaybe} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setKnowsAboutTrans = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional YesNoMaybe would_change_opinion_if_not_trans = 19;
 * @return {!proto.api.YesNoMaybe}
 */
proto.api.SurveyPerson.prototype.getWouldChangeOpinionIfNotTrans = function() {
  return /** @type {!proto.api.YesNoMaybe} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.api.YesNoMaybe} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setWouldChangeOpinionIfNotTrans = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string name_past = 20;
 * @return {string}
 */
proto.api.SurveyPerson.prototype.getNamePast = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setNamePast = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool is_group_past = 21;
 * @return {boolean}
 */
proto.api.SurveyPerson.prototype.getIsGroupPast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setIsGroupPast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool is_in_present = 22;
 * @return {boolean}
 */
proto.api.SurveyPerson.prototype.getIsInPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setIsInPresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool is_in_past = 23;
 * @return {boolean}
 */
proto.api.SurveyPerson.prototype.getIsInPast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.setIsInPast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * repeated Answer answers = 24;
 * @return {!Array<!proto.api.Answer>}
 */
proto.api.SurveyPerson.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.api.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Answer, 24));
};


/**
 * @param {!Array<!proto.api.Answer>} value
 * @return {!proto.api.SurveyPerson} returns this
*/
proto.api.SurveyPerson.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.api.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Answer}
 */
proto.api.SurveyPerson.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.api.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.SurveyPerson} returns this
 */
proto.api.SurveyPerson.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.QuestionGroup.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.QuestionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.api.QuestionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.QuestionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.QuestionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.api.Question.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.QuestionGroup}
 */
proto.api.QuestionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.QuestionGroup;
  return proto.api.QuestionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.QuestionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.QuestionGroup}
 */
proto.api.QuestionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.api.QuestionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOptions(value);
      break;
    case 5:
      var value = new proto.api.Question;
      reader.readMessage(value,proto.api.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.QuestionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.QuestionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.QuestionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.QuestionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.api.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.QuestionGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.QuestionGroup} returns this
 */
proto.api.QuestionGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.api.QuestionGroup.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.QuestionGroup} returns this
 */
proto.api.QuestionGroup.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional QuestionType type = 3;
 * @return {!proto.api.QuestionType}
 */
proto.api.QuestionGroup.prototype.getType = function() {
  return /** @type {!proto.api.QuestionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.api.QuestionType} value
 * @return {!proto.api.QuestionGroup} returns this
 */
proto.api.QuestionGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string options = 4;
 * @return {!Array<string>}
 */
proto.api.QuestionGroup.prototype.getOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.QuestionGroup} returns this
 */
proto.api.QuestionGroup.prototype.setOptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.QuestionGroup} returns this
 */
proto.api.QuestionGroup.prototype.addOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.QuestionGroup} returns this
 */
proto.api.QuestionGroup.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * repeated Question questions = 5;
 * @return {!Array<!proto.api.Question>}
 */
proto.api.QuestionGroup.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.api.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Question, 5));
};


/**
 * @param {!Array<!proto.api.Question>} value
 * @return {!proto.api.QuestionGroup} returns this
*/
proto.api.QuestionGroup.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.api.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Question}
 */
proto.api.QuestionGroup.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.api.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.QuestionGroup} returns this
 */
proto.api.QuestionGroup.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Question.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Question.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Question} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Question.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Question}
 */
proto.api.Question.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Question;
  return proto.api.Question.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Question}
 */
proto.api.Question.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Question.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Question.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.Question.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Question} returns this
 */
proto.api.Question.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.api.Question.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Question} returns this
 */
proto.api.Question.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Answer.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Answer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Answer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Answer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Answer}
 */
proto.api.Answer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Answer;
  return proto.api.Answer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Answer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Answer}
 */
proto.api.Answer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Answer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Answer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Answer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Answer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.Answer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Answer} returns this
 */
proto.api.Answer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.api.Answer.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Answer} returns this
 */
proto.api.Answer.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.Circle.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Circle.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Circle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Circle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Circle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    periodNow: jspb.Message.getFieldWithDefault(msg, 4, ""),
    periodPast: jspb.Message.getFieldWithDefault(msg, 5, ""),
    titlesNowList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    titlesPastList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Circle}
 */
proto.api.Circle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Circle;
  return proto.api.Circle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Circle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Circle}
 */
proto.api.Circle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodNow(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodPast(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTitlesNow(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTitlesPast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Circle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Circle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Circle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Circle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPeriodNow();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPeriodPast();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitlesNowList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getTitlesPastList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.Circle.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_default = 2;
 * @return {boolean}
 */
proto.api.Circle.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.api.Circle.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string period_now = 4;
 * @return {string}
 */
proto.api.Circle.prototype.getPeriodNow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.setPeriodNow = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string period_past = 5;
 * @return {string}
 */
proto.api.Circle.prototype.getPeriodPast = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.setPeriodPast = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string titles_now = 6;
 * @return {!Array<string>}
 */
proto.api.Circle.prototype.getTitlesNowList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.setTitlesNowList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.addTitlesNow = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.clearTitlesNowList = function() {
  return this.setTitlesNowList([]);
};


/**
 * repeated string titles_past = 7;
 * @return {!Array<string>}
 */
proto.api.Circle.prototype.getTitlesPastList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.setTitlesPastList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.addTitlesPast = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Circle} returns this
 */
proto.api.Circle.prototype.clearTitlesPastList = function() {
  return this.setTitlesPastList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.AdminCircle.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AdminCircle.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AdminCircle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AdminCircle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AdminCircle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    periodNowDe: jspb.Message.getFieldWithDefault(msg, 4, ""),
    periodNowEn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    periodPastDe: jspb.Message.getFieldWithDefault(msg, 6, ""),
    periodPastEn: jspb.Message.getFieldWithDefault(msg, 7, ""),
    titlesNowList: jspb.Message.toObjectList(msg.getTitlesNowList(),
    proto.api.AdminCircleTitle.toObject, includeInstance),
    titlesPastList: jspb.Message.toObjectList(msg.getTitlesPastList(),
    proto.api.AdminCircleTitle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AdminCircle}
 */
proto.api.AdminCircle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AdminCircle;
  return proto.api.AdminCircle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AdminCircle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AdminCircle}
 */
proto.api.AdminCircle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodNowDe(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodNowEn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodPastDe(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodPastEn(value);
      break;
    case 8:
      var value = new proto.api.AdminCircleTitle;
      reader.readMessage(value,proto.api.AdminCircleTitle.deserializeBinaryFromReader);
      msg.addTitlesNow(value);
      break;
    case 9:
      var value = new proto.api.AdminCircleTitle;
      reader.readMessage(value,proto.api.AdminCircleTitle.deserializeBinaryFromReader);
      msg.addTitlesPast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AdminCircle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AdminCircle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AdminCircle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AdminCircle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPeriodNowDe();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPeriodNowEn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPeriodPastDe();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPeriodPastEn();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTitlesNowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.api.AdminCircleTitle.serializeBinaryToWriter
    );
  }
  f = message.getTitlesPastList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.api.AdminCircleTitle.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.AdminCircle.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.AdminCircle} returns this
 */
proto.api.AdminCircle.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.api.AdminCircle.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AdminCircle} returns this
 */
proto.api.AdminCircle.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_default = 3;
 * @return {boolean}
 */
proto.api.AdminCircle.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.AdminCircle} returns this
 */
proto.api.AdminCircle.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string period_now_de = 4;
 * @return {string}
 */
proto.api.AdminCircle.prototype.getPeriodNowDe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AdminCircle} returns this
 */
proto.api.AdminCircle.prototype.setPeriodNowDe = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string period_now_en = 5;
 * @return {string}
 */
proto.api.AdminCircle.prototype.getPeriodNowEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AdminCircle} returns this
 */
proto.api.AdminCircle.prototype.setPeriodNowEn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string period_past_de = 6;
 * @return {string}
 */
proto.api.AdminCircle.prototype.getPeriodPastDe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AdminCircle} returns this
 */
proto.api.AdminCircle.prototype.setPeriodPastDe = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string period_past_en = 7;
 * @return {string}
 */
proto.api.AdminCircle.prototype.getPeriodPastEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AdminCircle} returns this
 */
proto.api.AdminCircle.prototype.setPeriodPastEn = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated AdminCircleTitle titles_now = 8;
 * @return {!Array<!proto.api.AdminCircleTitle>}
 */
proto.api.AdminCircle.prototype.getTitlesNowList = function() {
  return /** @type{!Array<!proto.api.AdminCircleTitle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AdminCircleTitle, 8));
};


/**
 * @param {!Array<!proto.api.AdminCircleTitle>} value
 * @return {!proto.api.AdminCircle} returns this
*/
proto.api.AdminCircle.prototype.setTitlesNowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.api.AdminCircleTitle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AdminCircleTitle}
 */
proto.api.AdminCircle.prototype.addTitlesNow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.api.AdminCircleTitle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.AdminCircle} returns this
 */
proto.api.AdminCircle.prototype.clearTitlesNowList = function() {
  return this.setTitlesNowList([]);
};


/**
 * repeated AdminCircleTitle titles_past = 9;
 * @return {!Array<!proto.api.AdminCircleTitle>}
 */
proto.api.AdminCircle.prototype.getTitlesPastList = function() {
  return /** @type{!Array<!proto.api.AdminCircleTitle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AdminCircleTitle, 9));
};


/**
 * @param {!Array<!proto.api.AdminCircleTitle>} value
 * @return {!proto.api.AdminCircle} returns this
*/
proto.api.AdminCircle.prototype.setTitlesPastList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.api.AdminCircleTitle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AdminCircleTitle}
 */
proto.api.AdminCircle.prototype.addTitlesPast = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.api.AdminCircleTitle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.AdminCircle} returns this
 */
proto.api.AdminCircle.prototype.clearTitlesPastList = function() {
  return this.setTitlesPastList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AdminCircleTitle.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AdminCircleTitle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AdminCircleTitle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AdminCircleTitle.toObject = function(includeInstance, msg) {
  var f, obj = {
    en: jspb.Message.getFieldWithDefault(msg, 1, ""),
    de: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AdminCircleTitle}
 */
proto.api.AdminCircleTitle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AdminCircleTitle;
  return proto.api.AdminCircleTitle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AdminCircleTitle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AdminCircleTitle}
 */
proto.api.AdminCircleTitle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AdminCircleTitle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AdminCircleTitle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AdminCircleTitle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AdminCircleTitle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDe();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string en = 1;
 * @return {string}
 */
proto.api.AdminCircleTitle.prototype.getEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AdminCircleTitle} returns this
 */
proto.api.AdminCircleTitle.prototype.setEn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string de = 2;
 * @return {string}
 */
proto.api.AdminCircleTitle.prototype.getDe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AdminCircleTitle} returns this
 */
proto.api.AdminCircleTitle.prototype.setDe = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.api.YesNoMaybe = {
  YES_NO_MAYBE_UNKNOWN: 0,
  NO: 1,
  YES: 2,
  PARTLY: 3,
  MAYBE: 4,
  YES_LESS: 5,
  YES_MORE: 6
};

/**
 * @enum {number}
 */
proto.api.Relationship = {
  NONE: 0,
  PARTNER: 1,
  EX_PARTNER: 2,
  CHILD: 3,
  PARENTS: 4,
  SIBLING: 5,
  RELATIVE: 6,
  BEST_FRIEND: 7,
  FRIEND: 8,
  BETTER_KNOWN_PERSON: 9,
  COLLEAGUE: 10,
  SUPERVISOR: 11,
  PROFESSIONAL: 12,
  OTHER_RELATIONSHIP: 999
};

/**
 * @enum {number}
 */
proto.api.ContactFrequency = {
  UNKNOWN: 0,
  DAILY: 1,
  SEVERAL_TIMES_A_WEEK: 2,
  ONCE_A_WEEK: 3,
  SEVERAL_TIMES_A_MONTH: 4,
  ONCE_A_MONTH: 5,
  SEVERAL_TIMES_A_YEAR: 6,
  ONCE_A_YEAR: 7,
  LESS: 8
};

/**
 * @enum {number}
 */
proto.api.QuestionType = {
  DEFAULT: 0,
  DETAILED: 1,
  GOOD_VS_BAD: 2
};

goog.object.extend(exports, proto.api);
