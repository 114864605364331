import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PersonCanvasComponent} from './components/person-canvas/person-canvas.component';
import {LoginComponent} from './admin/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {ExampleComponent} from './pages/example/example.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatLine, MatNativeDateModule} from '@angular/material/core';
import {PersonAddComponent} from './components/person-add/person-add.component';
import {PersonDeleteComponent} from './components/person-delete/person-delete.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {HttpClientModule} from '@angular/common/http';
import {TranslocoRootModule} from './transloco/transloco-root.module';
import {PersonFormComponent} from './components/person-form/person-form.component';
import {FormRestartComponent} from './components/form-restart/form-restart.component';
import {PersonQuestionsComponent} from './components/person-questions/person-questions.component';
import {TermsDialogComponent} from './components/terms-dialog/terms-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatTab, MatTabGroup} from '@angular/material/tabs';

@NgModule({
  declarations: [
    AppComponent,
    PersonCanvasComponent,
    LoginComponent,
    ToolbarComponent,
    ExampleComponent,
    NotFoundComponent,
    PersonAddComponent,
    PersonDeleteComponent,
    PersonFormComponent,
    FormRestartComponent,
    PersonQuestionsComponent,
    TermsDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    HttpClientModule,
    TranslocoRootModule,
    MatSelectModule,
    MatRadioModule,
    MatLine,
    MatTabGroup,
    MatTab
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    MatBottomSheet
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
