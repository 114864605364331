import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss']
})
export class TermsDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TermsDialogOptions,
    private dialog: MatDialog
  ) {

  }

  close(): void {
    this.dialog.closeAll();
  }
}

export type TermsDialogType = 'tos' | 'privacy';

export class TermsDialogOptions {
  type: TermsDialogType;

  constructor(type: TermsDialogType) {
    this.type = type;
  }
}
