import {Injectable} from '@angular/core';
import {BaseGrpcService} from './base-grpc-service';
import {SurveyServiceClient} from '../../grpc/ApiServiceClientPb';
import {Observable} from 'rxjs';
import {
  ApplicationState,
  Circle,
  Empty,
  GetCircleResponse,
  GetCirclesResponse,
  GetQuestionsResponse,
  IdRequest,
  LanguageRequest,
  QuestionGroup,
  Survey, SurveyCircle
} from '../../grpc/api_pb';
import {map} from 'rxjs/operators';
import {TranslocoService} from '@ngneat/transloco';
import {FormState} from '../model/form-state';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BaseGrpcService {

  private client: SurveyServiceClient;

  constructor(
    private ts: TranslocoService
  ) {
    super();
    this.client = new SurveyServiceClient('');
  }

  protected onSessionInvalidation(): void {
    // this.router.navigate(['/login']);
  }

  getCircle(id: number = 0): Observable<Circle> {
    return this.callGrpc<GetCircleResponse>(callback => this.client.getCircle(
      new IdRequest().setId(id).setLang(this.ts.getActiveLang()), null, callback)).pipe(map(
      result => {
        const circle = result.getCircle();
        if (circle === undefined) {
          throw new Error('No Circle retrieved');
        }
        return circle;
      }));
  }

  getCircles(): Observable<Circle[]> {
    return this.callGrpc<GetCirclesResponse>(callback => this.client.getCircles(
      new LanguageRequest().setLang(this.ts.getActiveLang()), null, callback)).pipe(map(
        result => result.getCirclesList()));
  }

  getQuestions(): Observable<QuestionGroup[]> {
    return this.callGrpc<GetQuestionsResponse>(callback => this.client.getQuestions(
      new LanguageRequest().setLang(this.ts.getActiveLang()), null, callback)).pipe(map(
      result => {
        const groups = result.getGroupsList();
        if (groups === undefined) {
          throw new Error('No Circle retrieved');
        }
        return groups;
      }));
  }

  getVersion(): Observable<string> {
    return this.callGrpc<ApplicationState>(callback => this.client.getApplicationState(
      new Empty(), null, callback)).pipe(map(result => result.getVersion()));
  }

  saveForm(formState: FormState): Observable<Empty> {
    const survey = new Survey()
      .setCirclesList(formState.circles.map(circle => {
        return new SurveyCircle()
          .setCircleId(circle.circle.getId())
          .setPersonsList(circle.persons.map(personWrapper => {
            personWrapper.person.setCircleAngle(personWrapper.angle ?? 0);
            personWrapper.person.setCircleNr(personWrapper.rank ?? 0);
            personWrapper.person.setIsImportant(personWrapper.isImportant);
            personWrapper.person.setAbbreviation(personWrapper.person.getFullName());
            personWrapper.person.setIsInPresent(personWrapper.isInPresent);
            personWrapper.person.setIsInPast(personWrapper.isInPast);
            return personWrapper.person;
          }));
      }))
      .setAbbreviation(formState.name ?? '')
      .setStartTime((formState.start ?? new Date()).getTime())
      .setEndTime(new Date().getTime())
      .setPrivacyPolicyAccepted(formState.privacyAccepted)
      .setTermsOfUseAccepted(formState.tosAccepted);

    return this.callGrpc<Empty>(callback => this.client.saveSurvey(
      survey, null, callback));
  }
}
