import {Injectable} from '@angular/core';
import {cipher, md, pki, util} from 'node-forge';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() {
  }

  public parsePrivateKey(pem: string): pki.rsa.PrivateKey {
    return pki.privateKeyFromPem(pem);
  }

  // For more info about the encryption see README.md and api/survey.go
  public decrypt(payload: string, privateKey: pki.rsa.PrivateKey): string | undefined {
    const keyLength = (privateKey.n as any).bitLength() / 8;
    const encryptedKey = payload.substr(0, keyLength);
    const iv = payload.substr(keyLength, 16);
    const encryptedText = payload.substr(keyLength + 16);
    const key = privateKey.decrypt(encryptedKey, 'RSA-OAEP', {md: md.sha256.create()});
    const decipher = cipher.createDecipher('AES-CTR', key);
    decipher.start({iv});
    decipher.update(util.createBuffer(encryptedText));
    if (decipher.finish()) {
      // console.log(decipher.output.toString());
      return decipher.output.toString();
    }
    return undefined;
  }

}

export function bin2String(array: number[]): string {
  let result = '';
  for (const item of array) {
    result += String.fromCharCode(item);
  }
  return result;
}
