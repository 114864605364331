import {Point} from './point';
import {SurveyPerson} from '../../grpc/api_pb';
import {BinaryWriter} from 'google-protobuf';
import {Base64Converter} from '../util/base-64-converter';

export class PersonWrapper {
  id = new Date().getTime();
  isDragging = false;
  isImportant = false;
  isInPresent = false;
  isInPast = false;
  rank?: number;
  angle?: number;

  tmpPoint?: Point;
  mouseMovePoint?: Point;

  hasAnsweredPersonDetails = false;
  person: SurveyPerson = new SurveyPerson();

  static getAvatarPath(isGroup?: boolean): string {
    if (isGroup === undefined || isGroup === null) {
      return 'assets/avatar-default.svg';
    }

    if (isGroup) {
      return 'assets/avatar-group.svg';
    } else {
      return 'assets/avatar-person.svg';
    }
  }

  getAvatarPath(): string {
    if (this.hasAnsweredPersonDetails) {
      return PersonWrapper.getAvatarPath(this.isInPresent ? this.person.getIsGroup() : this.person.getIsGroupPast());
    } else {
      return PersonWrapper.getAvatarPath(undefined);
    }
  }

  containsEvent(event: Point, imageSize: number): boolean {
    const halfImageSize = imageSize / 2;
    const left = (this.tmpPoint?.x ?? 0) - halfImageSize;
    const right = (this.tmpPoint?.x ?? 0) + halfImageSize;
    const top = (this.tmpPoint?.y ?? 0) - halfImageSize;
    const bottom = (this.tmpPoint?.y ?? 0) + halfImageSize;

    return event.x >= left && event.x <= right && event.y >= top && event.y <= bottom;
  }

  isAddedToCanvas(): boolean {
    return this.rank !== undefined && this.angle !== undefined;
  }

  toJson(): any {
    const personWriter = new BinaryWriter();
    SurveyPerson.serializeBinaryToWriter(this.person, personWriter);
    return {
      id: this.id,
      isDragging: this.isDragging,
      isImportant: this.isImportant,
      rank: this.rank,
      angle: this.angle,
      tmpPoint: this.tmpPoint,
      mouseMovePoint: this.mouseMovePoint,
      hasAnsweredPersonDetails: this.hasAnsweredPersonDetails,
      isInPresent: this.isInPresent,
      isInPast: this.isInPast,
      person: personWriter.getResultBase64String()
    };
  }

  fromJson(json: any): void {
    this.id = json.id;
    this.isDragging = json.isDragging;
    this.isImportant = json.isImportant;
    this.rank = json.rank;
    this.angle = json.angle;
    this.tmpPoint = json.tmpPoint;
    this.mouseMovePoint = json.mouseMovePoint;
    this.hasAnsweredPersonDetails = json.hasAnsweredPersonDetails;
    this.isInPresent = json.isInPresent;
    this.isInPast = json.isInPast;
    this.person = SurveyPerson.deserializeBinary(Base64Converter.toUint8Array(json.person));
  }
}
