import {Point} from '../../model/point';
import {Rect} from '../../model/rect';

export class CanvasUtils {
  static isInCircle(point: Point | undefined, radius: number, circleCenter: Point): boolean {
    if (!point) {
      return false;
    }

    return Math.pow(point.x - circleCenter.x, 2) + Math.pow(point.y - circleCenter.y, 2) < Math.pow(radius, 2);
  }

  static isInRect(point: Point | undefined, left: number, top: number, width: number, height: number): boolean {
    if (!point) {
      return false;
    }
    const right = left + width;
    const bottom = top + height;
    return point.x >= left && point.x <= right && point.y >= top && point.y <= bottom;
  }

  static isOverlappingRects(a: Rect, b: Rect): boolean {
    return a.left < b.right && a.right > b.left && a.top < b.bottom && a.bottom > b.top;
  }
}
