import {Injectable} from '@angular/core';
import {FormState} from '../model/form-state';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  private static readonly STORAGE_PREFIX = 'socnet_';

  constructor() {

  }

  persistFormState(formState: FormState): void {
    localStorage.setItem(`${PersistenceService.STORAGE_PREFIX}form_state`, JSON.stringify(formState.toJson()));
  }

  clearFormState(): void {
    localStorage.removeItem(`${PersistenceService.STORAGE_PREFIX}form_state`);
  }

  readFormState(): FormState | undefined {
    const item = localStorage.getItem(`${PersistenceService.STORAGE_PREFIX}form_state`);
    if (item === null) {
      return undefined;
    }
    const state = new FormState();
    state.fromJson(JSON.parse(item));
    return state;
  }
}
