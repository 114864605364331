import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalChangeService {

  changes: Subject<GlobalChangeType> = new Subject<GlobalChangeType>();

  constructor() {}

  publishChange(change: GlobalChangeType): void {
    this.changes.next(change);
  }
}

export enum GlobalChangeType {
  LANG_CHANGE
}
