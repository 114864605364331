import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PersonExtra} from '../../model/person-extra';
import {PersonWrapper} from '../../model/person-wrapper';
import {ModalHelper} from '../../util/modal-helper';

@Component({
  selector: 'app-person-delete',
  templateUrl: './person-delete.component.html',
  styleUrls: ['./person-delete.component.scss']
})
export class PersonDeleteComponent implements OnInit {

  data: PersonExtra;

  constructor(
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) bottomSheetData: PersonExtra,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: PersonExtra,
    @Optional() private bottomSheetRef: MatBottomSheetRef,
    @Optional() private dialogRef: MatDialogRef<PersonDeleteComponent>
  ) {
    this.data = ModalHelper.extractData(dialogData, bottomSheetData);
  }

  ngOnInit(): void {
  }

  close(shouldDelete: boolean): void {
    ModalHelper.close(this.dialogRef, this.bottomSheetRef, new PersonDeleteResult(shouldDelete, this.data.personWrapper));
  }
}

export class PersonDeleteResult {
  shouldDelete: boolean;
  person: PersonWrapper;

  constructor(shouldDelete: boolean, person: PersonWrapper) {
    this.shouldDelete = shouldDelete;
    this.person = person;
  }
}
