import {Injectable} from '@angular/core';
import {Circle} from '../../grpc/api_pb';
import {ApiService} from './api.service';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CircleService {

  initialized$: ReplaySubject<void> = new ReplaySubject<void>(1);
  private circles?: Circle[];

  constructor(private apiService: ApiService) {
    this.reloadCircles();
  }

  isCircleLoaded(): boolean {
    return this.circles !== undefined;
  }

  getCircles(): Circle[] {
    // tslint:disable-next-line:no-non-null-assertion
    return this.circles!;
  }

  reloadCircles(): void {
    this.apiService.getCircles().subscribe(circles => {
      console.log('loaded circles');
      this.circles = circles;
      this.initialized$.next();
    });
  }
}
