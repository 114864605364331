import {Component, Optional} from '@angular/core';
import {ModalHelper} from '../../util/modal-helper';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-form-restart',
  templateUrl: './form-restart.component.html',
  styleUrls: ['./form-restart.component.scss']
})
export class FormRestartComponent {

  constructor(
    @Optional() private bottomSheetRef: MatBottomSheetRef,
    @Optional() private dialogRef: MatDialogRef<FormRestartComponent>
  ) { }

  close(shouldRestart: boolean): void {
    ModalHelper.close(this.dialogRef, this.bottomSheetRef, new FormRestartResult(shouldRestart));
  }

}

export class FormRestartResult {
  shouldRestart: boolean;

  constructor(shouldRestart: boolean) {
    this.shouldRestart = shouldRestart;
  }
}
