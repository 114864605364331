export class Rect {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;


  constructor(left: number, top: number, width: number, height: number) {
    this.left = left;
    this.top = top;
    this.width = width;
    this.height = height;
    this.right = left + width;
    this.bottom = top + height;
  }
}
