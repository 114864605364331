<mat-card>
  <form [formGroup]="form">
    <div *ngIf="loginError" class="mat-error">Login war leider nicht erfolgreich</div>
    <div *ngIf="!loginError">Login</div>
    <mat-form-field>
      <mat-label>Benutzername</mat-label>
      <input matInput type="text" formControlName="username">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Passwort</mat-label>
      <input matInput type="password" formControlName="password">
    </mat-form-field>
    <button mat-raised-button (click)="login()" [disabled]="!form.valid">Login</button>
  </form>
</mat-card>
