import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {ComponentType} from '@angular/cdk/overlay';
import {Observable} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

export class ModalHelper {
  static open<T>(
    dialogService: MatDialog,
    bottomSheetService: MatBottomSheet,
    component: ComponentType<unknown>,
    data?: T
  ): Observable<any | undefined> {
    if (window.innerWidth >= 1200) {
      return dialogService.open(component, {
        data
      }).afterClosed();
    } else {
      return bottomSheetService.open(component, {
        data
      }).afterDismissed();
    }
  }

  static extractData<T>(dialogData: any, bottomSheetData: any): T {
    if (dialogData) {
      return dialogData;
    }
    return bottomSheetData;
  }

  static disableBackdropClose(
    dialogRef?: MatDialogRef<unknown>,
    bottomSheetRef?: MatBottomSheetRef
  ): void {
    if (dialogRef) {
      dialogRef.disableClose = true;
    }
    if (bottomSheetRef) {
      bottomSheetRef.disableClose = true;
    }
  }

  static close<T>(
    dialogRef?: MatDialogRef<unknown>,
    bottomSheetRef?: MatBottomSheetRef,
    result?: T
  ): void {
    if (dialogRef) {
      dialogRef.close(result);
    }
    if (bottomSheetRef) {
      bottomSheetRef.dismiss(result);
    }
  }
}
