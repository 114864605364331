import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {PersonWrapper} from '../../model/person-wrapper';
import {ImageWrapper} from '../../model/image-wrapper';
import {Point} from '../../model/point';
import {Rect} from '../../model/rect';
import {CanvasUtils} from './canvas-utils';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {PersonDeleteComponent, PersonDeleteResult} from '../person-delete/person-delete.component';
import {PersonExtra} from '../../model/person-extra';
import {PersonAddComponent, PersonAddData, PersonAddResult} from '../person-add/person-add.component';
import {Circle, Relationship} from '../../../grpc/api_pb';
import {ModalHelper} from '../../util/modal-helper';
import {TranslocoService} from '@ngneat/transloco';
import {EnumTranslator} from '../../util/enum-translator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-person-canvas',
  templateUrl: './person-canvas.component.html',
  styleUrls: ['./person-canvas.component.scss']
})
export class PersonCanvasComponent implements AfterViewInit, OnChanges {

  static readonly FONT_SIZE = 16;
  static readonly FONT_COLOR = '#0000000';
  static readonly CIRCLE_STROKE_COLOR = 'rgba(0, 0, 0, .4)';

  @Output() personChange: EventEmitter<PersonWrapper[]> = new EventEmitter<PersonWrapper[]>();

  @ViewChild('personCanvas')
  canvas!: ElementRef<HTMLCanvasElement>;
  context!: CanvasRenderingContext2D;

  @Input() circle!: Circle;
  @Input() viewMode: PersonCanvasViewMode = 'drag_drop_now';

  get isDragDropViewMode(): boolean {
    return this.viewMode === 'drag_drop_now' || this.viewMode === 'drag_drop_past';
  }

  get isPrintViewMode(): boolean {
    return this.viewMode === 'print_now' || this.viewMode === 'print_past';
  }

  // tslint:disable-next-line:variable-name

  @Input() activePerson?: PersonWrapper;

  @Input() set savedPersons(_: PersonWrapper[]) {
  }

  persons: PersonWrapper[] = [];
  imageMap: { [src: string]: ImageWrapper } = {};

  isHoverSchedulingForDeletion = false;
  currentHoverSegment = -1;

  hasRunFirstDraw = false;
  canvasRect!: DOMRect;
  canvasHeight!: number;
  canvasWidth!: number;
  canvasWidthHalf!: number;
  canvasHeightHalf!: number;
  circleFullRadius!: number;
  circleRadiusPart!: number;
  circleCenter!: Point;
  imageSize!: number;

  constructor(
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private ts: TranslocoService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasChangedViewMode = changes.viewMode !== undefined;
    const hasChangedCircle = changes.circle !== undefined;
    const hasChangedPersons = changes.savedPersons !== undefined;

    if (hasChangedPersons) {
      if (hasChangedCircle) {
        this.persons = [...changes.savedPersons.currentValue];
        this.persons.push(this.buildNewPerson());
      } else {
        changes.savedPersons.currentValue.forEach((person: PersonWrapper) => {
          const index = this.persons.findIndex(foundPerson => foundPerson.id === person.id);
          if (index !== -1) {
            return;
          }
          this.persons.push(person);
        });
      }
    }


    if (!this.hasRunFirstDraw) {
      if (this.canvas) {
        this.onFirstDraw(this.canvas.nativeElement.getBoundingClientRect());
      }
    } else if (hasChangedViewMode) {
      if (this.hasRunFirstDraw) {
        this.onFirstDraw(this.canvas.nativeElement.getBoundingClientRect());
      }
    } else {
      this.onDraw();
    }
  }

  ngAfterViewInit(): void {
    console.log('Initializing Person Canvas');
    const foundContext = this.canvas.nativeElement.getContext('2d', {
      willReadFrequently: true
    });
    if (foundContext) {
      this.context = foundContext;
      console.log(`Canvas Context retrieved.`);

      this.onFirstDraw(this.canvas.nativeElement.getBoundingClientRect());
    }
    this.canvas.nativeElement.addEventListener('touchstart', event =>
      this.onMouseDown(new Point(
        event.changedTouches[0].clientX - this.canvas.nativeElement.offsetLeft,
        event.changedTouches[0].clientY - this.canvas.nativeElement.offsetTop
      )));
    this.canvas.nativeElement.addEventListener('touchmove', event =>
      this.onMouseMove(new Point(
        event.changedTouches[0].clientX - this.canvas.nativeElement.offsetLeft,
        event.changedTouches[0].clientY - this.canvas.nativeElement.offsetTop
      )));
    this.canvas.nativeElement.addEventListener('touchend', event =>
      this.onMouseUp(new Point(
        event.changedTouches[0].clientX - this.canvas.nativeElement.offsetLeft,
        event.changedTouches[0].clientY - this.canvas.nativeElement.offsetTop
      )));
  }

  onMouseDownFromEvent(event: MouseEvent): void {
    this.onMouseDown(new Point(event.clientX - this.canvas.nativeElement.offsetLeft, event.clientY - this.canvas.nativeElement.offsetTop));
  }

  onMouseMoveFromEvent(event: MouseEvent): void {
    this.onMouseMove(new Point(event.clientX - this.canvas.nativeElement.offsetLeft, event.clientY - this.canvas.nativeElement.offsetTop));
  }

  onMouseUpFromEvent(event: MouseEvent): void {
    this.onMouseUp(new Point(event.clientX - this.canvas.nativeElement.offsetLeft, event.clientY - this.canvas.nativeElement.offsetTop));
  }

  toImageData(): string {
    this.onFirstDraw(new DOMRect(0, 0, 1280, 720));
    return this.canvas.nativeElement.toDataURL('image/jpeg', 1);
  }

  onMouseDown(event: Point): void {
    if (!this.isDragDropViewMode) {
      return;
    }
    this.persons.filter(person =>
      (this.viewMode !== 'drag_drop_past' || !person.isInPresent) &&
      person.containsEvent(event, this.imageSize)
    ).forEach(person => {
      person.isDragging = true;
      person.mouseMovePoint = new Point(event.x, event.y);
      this.checkPersonHoverState(person);
      this.onDraw();
    });
  }

  onMouseMove(event: Point): void {
    if (!this.isDragDropViewMode) {
      return;
    }
    this.persons.filter(person => person.isDragging).forEach(person => {
      person.mouseMovePoint = new Point(event.x, event.y);
      this.checkPersonHoverState(person);
      this.onDraw();
    });
  }

  onMouseUp(event: Point): void {
    if (this.viewMode === 'pick') {
      const pickedPersonIndex = this.persons.findIndex(person => person.containsEvent(event, this.imageSize));
      if (pickedPersonIndex === -1) {
        return;
      }
      const importantPersonCount = this.persons.filter(person => person.isImportant).length;
      if ((importantPersonCount < 3 && !this.persons[pickedPersonIndex].isImportant) || this.persons[pickedPersonIndex].isImportant) {
        this.persons[pickedPersonIndex].isImportant = !this.persons[pickedPersonIndex].isImportant;
        this.publishPersonsChange();
        this.onDraw();
      } else {
        this.snackbar.open(this.ts.translate('canvas.maxReachedError'), undefined, {duration: 5000});
      }
    } else if (this.viewMode === 'drag_drop_past') {
      const pickedPersonIndex = this.persons.findIndex(person => person.containsEvent(event, this.imageSize));
      if (pickedPersonIndex !== -1 && this.persons[pickedPersonIndex].isAddedToCanvas() && this.persons[pickedPersonIndex].isInPresent) {
        this.persons[pickedPersonIndex].isInPast = !this.persons[pickedPersonIndex].isInPast;
        this.persons[pickedPersonIndex].isDragging = false;
        this.publishPersonsChange();
        this.onDraw();
      } else {
        this.onDragAndDropMouseUp(event);
      }
    } else if (this.viewMode === 'drag_drop_now') {
      this.onDragAndDropMouseUp(event);
    }
  }

  onDragAndDropMouseUp(event: Point): void {
    this.persons.filter(person => person.isDragging).forEach(person => {
      person.mouseMovePoint = undefined;
      person.tmpPoint = undefined;
      let wasAddedToCanvas = true;

      if (this.isHoverSchedulingForDeletion) {
        person.isDragging = false;
        ModalHelper
          .open(this.dialog, this.bottomSheet, PersonDeleteComponent, new PersonExtra(person))
          .subscribe((result: PersonDeleteResult) => this.onPersonDeletion(result));
      } else if (this.currentHoverSegment === 4) {
        // We're hovering over the Center, so reset to the Start
        // if the Person wasn't added to the Circle previously.
        person.isDragging = false;
        if (!person.isAddedToCanvas()) {
          person.tmpPoint = new Point(this.canvasWidthHalf / 2, this.canvasHeightHalf);
        }
        this.currentHoverSegment = -1;
      } else if (this.currentHoverSegment !== -1) {
        wasAddedToCanvas = person.isAddedToCanvas();
        person.rank = (this.currentHoverSegment - 4) * -1;
        person.angle = this.findPersonAngle(person, event.x, event.y);
        person.isDragging = false;
        if (this.persons.filter(foundPerson => !foundPerson.isAddedToCanvas()).length === 0) {
          this.persons.push(this.buildNewPerson());
        }
        this.publishPersonsChange();
        this.currentHoverSegment = -1;
      } else {
        person.isDragging = false;
        person.tmpPoint = new Point(event.x, event.y);
      }

      this.onDraw();

      if (!wasAddedToCanvas) {
        ModalHelper
          .open(this.dialog, this.bottomSheet, PersonAddComponent, new PersonAddData(person, this.circle.getId() === 1 ? 'support' : 'appreciation', this.viewMode === 'drag_drop_past'))
          .subscribe((result: PersonAddResult) => this.onPersonAddition(result));
      }
    });
  }

  publishPersonsChange(): void {
    this.personChange.emit(this.persons.filter(person => person.hasAnsweredPersonDetails));
  }

  onPersonAddition(result: PersonAddResult): void {
    const index = this.persons.findIndex(foundPerson => foundPerson.id === result.person.id);
    if (index === -1) {
      return;
    }
    if (result.shouldAdd) {
      this.persons[index] = result.person;
      this.publishPersonsChange();
    } else {
      this.persons.splice(index, 1);
    }

    this.onDraw();
  }

  onPersonDeletion(result: PersonDeleteResult): void {
    if (result.shouldDelete) {
      const index = this.persons.findIndex(foundPerson => foundPerson.id === result.person.id);
      if (index === -1) {
        return;
      }
      this.persons.splice(index, 1);
      this.publishPersonsChange();
    }
    this.isHoverSchedulingForDeletion = false;
    this.onDraw();
  }

  findPersonAngle(person: PersonWrapper, x: number, y: number): number {
    let currentAngle = Math.atan2(this.circleCenter.y - y, x - this.circleCenter.x);
    currentAngle = currentAngle * 180 / Math.PI;
    currentAngle = Math.abs(currentAngle < 0 ? currentAngle * -1 : Math.abs(currentAngle - 180) + 180);

    const radius = ((this.circleCenter.y - 8) / 5 + 4) * ((person.rank ?? 0) + 1);

    this.persons.filter(filteredPerson => filteredPerson.rank === person.rank && !filteredPerson.isDragging).forEach(foundPerson => {
      if (person.rank !== foundPerson.rank || !foundPerson.angle) {
        return;
      }
      let point: Point;
      let foundPoint: Point;

      currentAngle -= 1;
      do {
        point = new Point(
          this.angleToX(currentAngle, radius) - this.imageSize / 2,
          this.angleToY(currentAngle, radius) - this.imageSize / 2
        );
        foundPoint = new Point(
          this.angleToX(foundPerson.angle ?? 0, radius) - this.imageSize / 2,
          this.angleToY(foundPerson.angle ?? 0, radius) - this.imageSize / 2
        );
        currentAngle += 1;
      } while (CanvasUtils.isOverlappingRects(
        new Rect(point.x, point.y, this.imageSize, this.imageSize),
        new Rect(foundPoint.x, foundPoint.y, this.imageSize, this.imageSize),
      ));
    });

    person.tmpPoint = new Point(
      this.angleToX(currentAngle, radius),
      this.angleToY(currentAngle, radius)
    );

    return currentAngle;
  }

  checkPersonHoverState(person: PersonWrapper): void {
    if (
      person.isAddedToCanvas() &&
      CanvasUtils.isInRect(person.mouseMovePoint, 0, 0, this.canvasWidthHalf, this.canvasHeight)
    ) {
      this.currentHoverSegment = -1;
      this.isHoverSchedulingForDeletion = true;
      return;
    } else {
      this.isHoverSchedulingForDeletion = false;
    }

    for (let a = 4; a >= 0; a--) {
      const radius = this.circleFullRadius - this.circleRadiusPart * a;

      let isInContainer = false;
      if (a > 0) {
        isInContainer = CanvasUtils.isInCircle(person.mouseMovePoint, radius, this.circleCenter);
      } else {
        isInContainer = CanvasUtils.isInRect(person.mouseMovePoint, this.canvasWidthHalf, 0, this.canvasWidthHalf, this.canvasHeight);
      }

      this.currentHoverSegment = isInContainer ? a : -1;

      if (isInContainer) {
        return;
      }
    }
  }

  onFirstDraw(canvasRect: DOMRect): void {
    this.hasRunFirstDraw = true;
    this.canvasRect = canvasRect;
    this.canvasWidth = canvasRect.width;
    this.canvasHeight = canvasRect.height;
    this.canvas.nativeElement.width = this.canvasWidth;
    this.canvas.nativeElement.height = this.canvasHeight;
    this.canvasWidthHalf = this.canvasWidth / 2;
    this.canvasHeightHalf = this.canvasHeight / 2;
    this.circleFullRadius = this.canvasHeightHalf - 8;
    this.circleRadiusPart = this.circleFullRadius / 5 + 4;
    this.circleCenter = !this.isDragDropViewMode ? new Point(this.canvasWidthHalf, this.canvasHeightHalf) : new Point(
      this.canvasWidthHalf + this.canvasWidthHalf / 2,
      this.canvasHeightHalf
    );

    this.imageSize = (this.circleCenter.y - 8) / 5 - 8;
    console.log(`Canvas Height: ${this.canvasHeight}, Width: ${this.canvasWidth}`);

    this.persons
      .filter(person => !person.isAddedToCanvas())
      .forEach(person => person.tmpPoint = new Point(this.canvasWidthHalf / 2, this.canvasHeightHalf));

    this.onDraw();
  }

  onDraw(): void {
    this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

    if (this.isPrintViewMode) {
      this.context.fillStyle = 'rgba(255, 255, 255, 1)';
      this.context.fillRect(0, 0, this.canvasWidthHalf, this.canvasHeight);
    }

    if (this.isHoverSchedulingForDeletion) {
      this.context.fillStyle = 'rgba(255, 0, 0, .2)';
      this.context.fillRect(0, 0, this.canvasWidthHalf, this.canvasHeight);
    }

    if (this.isDragDropViewMode) {
      this.onDrawExplanationTexts();
    }
    this.onDrawCircles();

    this.persons.filter(person => this.isDragDropViewMode || person.isAddedToCanvas()).forEach(person => {
      if (person.angle !== undefined && person.rank !== undefined && !person.isDragging) {
        this.onDrawPersonRelative(person);
      } else if (person.mouseMovePoint !== undefined) {
        this.onDrawPersonAbsolute(person, person.mouseMovePoint);
      } else if (person.tmpPoint !== undefined) {
        this.onDrawPersonAbsolute(person, person.tmpPoint);
      }
    });
  }

  onDrawExplanationTexts(): void {
    const isDraggingAddedPersons = this.persons.filter(person => person.isDragging && person.isAddedToCanvas()).length > 0;
    if (isDraggingAddedPersons) {
      const deleteHint = this.ts.translate('canvas.deletePerson');
      this.context.font = '16px sans-serif';
      this.context.fillStyle = 'rgba(0, 0, 0, .6)';
      const deleteTextWidth = this.context.measureText(deleteHint).width;
      this.context.fillText(deleteHint, this.canvasWidthHalf / 2 - deleteTextWidth / 2, this.canvasHeight - 32);

      const deleteIcon = this.getAvatarFromPath('assets/delete.svg');

      if (deleteIcon.loaded) {
        const imageSize = 20;
        this.context.drawImage(
          deleteIcon.image,
          this.canvasWidthHalf / 2 - deleteTextWidth / 2 - imageSize - 8,
          this.canvasHeight - 32 - imageSize / 2 - 7, imageSize, imageSize);
      } else {
        deleteIcon.loadListener = () => this.onDraw();
      }
    } else {
      const lines = [this.ts.translate('canvas.addPersonSecondLine'), this.ts.translate('canvas.addPersonFirstLine')];
      this.context.font = '14px sans-serif';
      this.context.fillStyle = 'rgba(0, 0, 0, .6)';
      let currentY = this.canvasHeightHalf - this.imageSize / 2 - 16;
      lines.forEach(line => {
        this.context.fillText(line, this.canvasWidthHalf / 2 - this.context.measureText(line).width / 2, currentY);
        currentY -= 14;
      });

      currentY -= 8;
      this.context.font = '20px sans-serif';
      this.context.fillStyle = '#000000';
      const addTitle = this.ts.translate('canvas.addPerson');
      this.context.fillText(addTitle, this.canvasWidthHalf / 2 - this.context.measureText(addTitle).width / 2, currentY);
    }
  }

  onDrawCircles(): void {
    for (let a = 0; a < 5; a++) {
      const radius = this.circleFullRadius - this.circleRadiusPart * a;
      if (a !== 0) {
        this.onDrawCircle(radius, this.currentHoverSegment === a && this.currentHoverSegment !== 4);
      } else {
        this.onDrawRect(this.currentHoverSegment === a);
      }
      if (this.viewMode !== 'view') {
        this.onDrawCircleText(
          this.viewMode !== 'drag_drop_past' && this.viewMode !== 'print_past' ?
            this.circle.getTitlesNowList()[Math.abs(a - 4)] :
            this.circle.getTitlesPastList()[Math.abs(a - 4)],
          radius,
          a
        );
      }
    }
  }

  onDrawRect(isHovering: boolean): void {
    this.context.beginPath();
    this.context.fillStyle = isHovering ? 'rgba(0, 0, 0, .05)' : '#ffffff';
    this.context.fillRect(this.canvasWidthHalf, 0, this.canvasWidthHalf, this.canvasHeight);
  }

  onDrawCircle(radius: number, isHovering: boolean): void {
    this.context.beginPath();

    this.context.arc(this.circleCenter.x, this.circleCenter.y, radius, 0, 2 * Math.PI);

    this.context.fillStyle = isHovering ? 'rgba(0, 0, 0, .05)' : '#ffffff';
    this.context.fill();
    this.context.lineWidth = 1;
    this.context.strokeStyle = PersonCanvasComponent.CIRCLE_STROKE_COLOR;
    this.context.stroke();
  }

  onDrawCircleText(text: string, radius: number, index: number): void {
    this.context.font = `${PersonCanvasComponent.FONT_SIZE}px sans-serif`;
    const textMetrics = this.context.measureText(text);

    this.context.fillStyle = '#000000';
    if (index === 4) {
      this.drawCircleCenterText(text);
    } else if (index === 0) {
      this.drawCircleOuterText(text);
    } else {
      this.drawCircleInnerText(text, this.circleCenter, radius * 2);
    }
  }

  drawCircleCenterText(text: string): void {
    this.context.fillText(
      text,
      this.circleCenter.x - this.context.measureText(text).width / 2,
      this.canvasHeight / 2 - (PersonCanvasComponent.FONT_SIZE / 4) * -1
    );
  }

  drawCircleOuterText(text: string): void {
    const endOfCircle = this.circleCenter.x + this.circleFullRadius - this.circleRadiusPart;
    const textWidth = this.context.measureText(text).width;
    if (textWidth + 16 >= this.canvasWidthHalf / 2) {
      const halfSentenceLength = text.length / 2;
      const words = text.split(' ');

      let lastIndex = words.length - 1;
      let currentLength = 0;
      for (let a = 0; a < words.length; a++) {
        currentLength += words[a].length;
        if (currentLength >= halfSentenceLength) {
          lastIndex = a;
          break;
        }
      }

      const firstLine = words.slice(0, lastIndex + 1).join(' ');
      const firstLineWidth = this.context.measureText(firstLine).width;
      const secondLine = words.slice(lastIndex + 1, words.length).join(' ');
      const secondLineWidth = this.context.measureText(secondLine).width;
      this.context.fillText(
        firstLine,
        this.isDragDropViewMode ?
          this.canvasWidth - firstLineWidth - 16 : endOfCircle - firstLineWidth,
        16
      );
      this.context.fillText(
        secondLine,
        this.isDragDropViewMode ?
          this.canvasWidth - secondLineWidth - 16 : endOfCircle - secondLineWidth,
        32
      );
    } else {
      this.context.fillText(
        text,
        this.isDragDropViewMode ?
          this.canvasWidth - textWidth - 16 : endOfCircle - textWidth,
        16
      );
    }
  }

  drawCircleInnerText(text: string, center: Point, radius: number, startAngle = 0): void {
    startAngle = startAngle * (Math.PI / 180); // convert to radians

    const textHeight = PersonCanvasComponent.FONT_SIZE;
    radius -= textHeight;

    this.context.fillStyle = '#0000000';
    this.context.font = `${textHeight}px sans-serif`;

    // Setup letters and positioning
    this.context.save();
    this.context.translate(center.x, center.y); // Move to center
    // startAngle += (Math.PI * !inwardFacing); // Rotate 180 if outward
    this.context.textBaseline = 'middle'; // Ensure we draw in exact center
    this.context.textAlign = 'center'; // Ensure we draw in exact center

    // rotate 50% of total angle for center alignment
    for (let j = 0; j < text.length; j++) {
      const charWid = this.context.measureText(text[j]).width;
      startAngle -= ((charWid + (j === text.length - 1 ? 0 : 0)) / (radius / 2 - textHeight)) / 2;
    }

    // Phew... now rotate into final start position
    this.context.rotate(startAngle);

    // Now for the fun bit: draw, rotate, and repeat
    for (const c of text) {
      const charWid = this.context.measureText(c).width; // half letter
      // rotate half letter
      this.context.rotate((charWid / 2) / (radius / 2 - textHeight));
      // draw the character at "top" or "bottom"
      // depending on inward or outward facing
      this.context.fillText(c, 0, (0 - radius / 2 + textHeight / 2));

      this.context.rotate((charWid / 2) / (radius / 2 - textHeight)); // rotate half letter
    }
    this.context.restore();
  }

  onDrawPersonAbsolute(person: PersonWrapper, point: Point): void {
    const avatar = this.getAvatarFromPerson(person);
    if (avatar.loaded) {
      this.drawImage(person, avatar.image, (point.x ?? 0) - this.imageSize / 2, (point.y ?? 0) - this.imageSize / 2);
      this.drawName(person, new Point(point.x, point.y + this.imageSize / 2 + 8));
    } else {
      avatar.loadListener = () => {
        this.onDraw();
      };
    }
  }

  onDrawPersonRelative(person: PersonWrapper): void {
    if (this.isPrintViewMode) {
      if (this.viewMode === 'print_now' && !person.isInPresent) {
        return;
      }
      if (this.viewMode === 'print_past' && !person.isInPast) {
        return;
      }
    }
    if (this.viewMode === 'view') {
      this.context.save();
      this.context.globalAlpha = person.id === this.activePerson?.id ? 1 : .3;
    }
    const avatar = this.getAvatarFromPerson(person);
    const radius = this.circleFullRadius - this.circleRadiusPart * (Math.abs((person.rank ?? 0) - 4)) + this.imageSize / 3;
    const point = new Point(this.angleToX(person.angle ?? 0, radius), this.angleToY(person.angle ?? 0, radius));
    person.tmpPoint = point;

    if (avatar.loaded) {
      this.drawImage(person, avatar.image, point.x - this.imageSize / 2, point.y - this.imageSize / 2);
      this.drawName(person, new Point(point.x, point.y + this.imageSize / 2 + 8));
    } else {
      avatar.loadListener = () => {
        this.onDraw();
      };
    }
    if (this.viewMode === 'view') {
      this.context.restore();
    }
  }

  getAvatarFromPerson(person: PersonWrapper): ImageWrapper {
    return this.getAvatarFromPath(person.getAvatarPath());
  }

  getAvatarFromPath(avatarPath: string): ImageWrapper {
    if (avatarPath in this.imageMap) {
      return this.imageMap[avatarPath];
    }

    const image = new ImageWrapper(avatarPath);
    this.imageMap[avatarPath] = image;
    return image;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.onFirstDraw(this.canvas.nativeElement.getBoundingClientRect());
  }

  drawImage(person: PersonWrapper, image: HTMLImageElement, x: number, y: number): void {
    this.context.drawImage(
      image,
      x,
      y,
      this.imageSize,
      this.imageSize
    );
    if (this.viewMode !== 'drag_drop_past' || person.isInPast) {
      return;
    }

    const imageData = this.context.getImageData(x, y, this.imageSize, this.imageSize);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      const brightness = 0.34 * data[i] + 0.5 * data[i + 1] + 0.16 * data[i + 2];
      // red
      data[i] = brightness;
      // green
      data[i + 1] = brightness;
      // blue
      data[i + 2] = brightness;
    }

    // overwrite original image
    this.context.putImageData(imageData, x, y);
  }

  drawName(person: PersonWrapper, point: Point): void {
    if (!person.hasAnsweredPersonDetails) {
      return;
    }
    let text: string;
    if (!person.isInPresent) {
      text = `${person.person.getNamePast()}`;
    } else {
      text = `${person.person.getFullName()} (${person.person.getAge()})`;
    }
    this.context.font = 'bold 12px sans-serif';

    let textWidth = this.context.measureText(text).width;

    if (this.isPrintViewMode) {
      this.context.fillStyle = '#ffffff';
      this.context.fillRect(point.x - textWidth / 2, point.y - 12, textWidth, 16);
    }

    this.context.fillStyle = person.isImportant ? 'rgba(255, 0, 0, .6)' : 'rgba(0, 0, 0, .6)';
    this.context.fillText(text, point.x - textWidth / 2, point.y);

    if (person.isInPresent) {
      const relationshipText = person.person.getRelationship() === Relationship.OTHER_RELATIONSHIP ?
        person.person.getOtherRelationship() : EnumTranslator.getTextFromRelationship(this.ts, person.person.getRelationship());
      textWidth = this.context.measureText(relationshipText).width;

      if (this.isPrintViewMode) {
        this.context.fillStyle = '#ffffff';
        this.context.fillRect(point.x - textWidth / 2, point.y + 2, textWidth, 16);
      }

      this.context.fillStyle = person.isImportant ? 'rgba(255, 0, 0, .6)' : 'rgba(0, 0, 0, .6)';
      this.context.fillText(relationshipText, point.x - textWidth / 2, point.y + 14);
    }
  }

  buildNewPerson(): PersonWrapper {
    const person = new PersonWrapper();
    person.tmpPoint = new Point(this.canvasWidthHalf / 2, this.canvasHeight / 2);
    switch (this.viewMode) {
      case 'drag_drop_now':
        person.isInPresent = true;
        break;
      case 'drag_drop_past':
        person.isInPast = true;
        break;
    }
    return person;
  }

  angleToX(angle: number, radius: number): number {
    const x = this.circleCenter.x;
    return (radius - this.imageSize) * Math.cos(angle * Math.PI / 180) + x;

  }

  angleToY(angle: number, radius: number): number {
    const y = this.canvasHeight / 2;
    return (radius - this.imageSize) * Math.sin(angle * Math.PI / 180) + y;
  }

}

export type PersonCanvasViewMode = 'drag_drop_now' | 'drag_drop_past' | 'pick' | 'view' | 'print_now' | 'print_past';
