import {Component, OnDestroy} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {Subscription} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';
import {GlobalChangeService, GlobalChangeType} from '../../services/global-change.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnDestroy {

  authenticated = false;

  private stateChangeSub: Subscription;

  constructor(
    public ts: TranslocoService,
    adminService: AdminService,
    private changes: GlobalChangeService
  ) {
    this.stateChangeSub = adminService.stateChange.subscribe(authenticated => this.authenticated = authenticated);
  }

  ngOnDestroy(): void {
    this.stateChangeSub.unsubscribe();
  }

  toggleLanguage(): void {
    const newLang = this.ts.getActiveLang() === 'de' ? 'en' : 'de';
    this.ts.setActiveLang(newLang);
    this.changes.publishChange(GlobalChangeType.LANG_CHANGE);
  }
}
