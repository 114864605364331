import {Injectable} from '@angular/core';
import {BaseGrpcService} from './base-grpc-service';
import {SurveyServiceClient} from '../../grpc/ApiServiceClientPb';
import {
  Circle,
  Empty,
  GetCircleResponse,
  GetQuestionsResponse,
  IdRequest,
  LanguageRequest,
  QuestionGroup,
  Survey
} from '../../grpc/api_pb';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SurveyService extends BaseGrpcService {

  private client: SurveyServiceClient;

  constructor() {
    super();
    this.client = new SurveyServiceClient('');
  }

  saveSurvey(survey: Survey): Observable<Empty> {
    return this.callGrpc<Empty>(callback => this.client.saveSurvey(survey, null, callback));
  }

  getQuestions(lang: string): Observable<QuestionGroup[]> {
    return this.callGrpc<GetQuestionsResponse>(callback => this.client.getQuestions(
      new LanguageRequest().setLang(lang), null, callback))
      .pipe(map(value => value.getGroupsList()));
  }

  getCircle(id: number, lang: string): Observable<Circle | undefined> {
    return this.callGrpc<GetCircleResponse>(callback => this.client.getCircle(
      new IdRequest().setId(id).setLang(lang), null, callback))
      .pipe(map(value => value.getCircle()));
  }

}
