import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './admin/login/login.component';
import {ExampleComponent} from './pages/example/example.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {PersonFormComponent} from './components/person-form/person-form.component';
import {loggedInGuard} from './admin/login/logged-in.guard';

const routes: Routes = [
  {path: '', component: PersonFormComponent},
  {path: 'login', component: LoginComponent},
  {
    path: 'admin',
    loadComponent: () => import('./admin/admin-dashboard/admin-dashboard.component').then(c => c.AdminDashboardComponent),
    canActivate: [ loggedInGuard() ],
    children: []
  },
  {path: 'example', component: ExampleComponent},
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
