import {Observable} from 'rxjs';

export abstract class BaseGrpcService {

  protected callGrpc<ResponseType>(
    callImpl: (callback: (err: Error, response: ResponseType) => void) => void,
    responseValidityChecker?: (response: ResponseType) => boolean
  ): Observable<ResponseType> {
    return new Observable<ResponseType>(s => {
      callImpl((err: Error, response: ResponseType) => {
        if (err != null) {
          if (err.message === 'unauthorized') {
            s.error(err);
            this.onSessionInvalidation();
          } else {
            console.log(err.message);
            s.error(err);
          }
          return;
        }
        if (response != null && (!responseValidityChecker || responseValidityChecker(response))) {
          s.next(response);
          s.complete();
        }
      });
    });
  }

  protected onSessionInvalidation(): void {

  }

}
