export class ImageWrapper {
  image: HTMLImageElement;
  loaded = false;
  loadListener?: () => void;

  constructor(src: string) {
    this.image = new Image();
    this.image.src = src;
    this.image.onload = () => {
      this.loaded = true;
      if (this.loadListener) {
        this.loadListener();
      }
    };
  }
}
