import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Answer, Question, QuestionGroup} from '../../../grpc/api_pb';
import {PersonWrapper} from '../../model/person-wrapper';
import {ApiService} from '../../services/api.service';
import {Subject} from 'rxjs';
import {GlobalChangeService, GlobalChangeType} from '../../services/global-change.service';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-person-questions',
  templateUrl: './person-questions.component.html',
  styleUrls: ['./person-questions.component.scss']
})
export class PersonQuestionsComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @Input() persons!: PersonWrapper[];
  get importantPersons(): PersonWrapper[] {
    return this.persons.filter(person => person.isImportant);
  }
  get currentPerson(): PersonWrapper {
    return this.importantPersons[this.currentPersonIndex];
  }
  @Output() personChange: EventEmitter<PersonWrapper[]> = new EventEmitter<PersonWrapper[]>();
  questionGroups!: QuestionGroup[];

  @Input() currentPersonIndex = 0;
  @Input() currentQuestionGroup = 0;
  @Output() currentPersonIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() currentQuestionGroupChange: EventEmitter<number> = new EventEmitter<number>();

  currentAnswers: Answer[] = [];

  constructor(private apiService: ApiService, private globalChange: GlobalChangeService) {
    this.loadQuestionGroups();
    this.globalChange.changes.pipe(filter(type => type === GlobalChangeType.LANG_CHANGE), takeUntil(this.destroy$)).subscribe(_ => {
      this.loadQuestionGroups();
    });
  }

  ngOnInit(): void {
    this.getAnswersFromCurrentPerson();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadQuestionGroups(): void {
    this.apiService.getQuestions().subscribe(groups => this.questionGroups = groups);
  }

  getQuestionTitleWithPersonName(question: Question): string {
    return question.getTitle().replace('%s', this.currentPerson.person.getFullName());
  }

  getAnswersFromCurrentPerson(): void {
    this.currentAnswers = this.currentPerson.person.getAnswersList();
  }

  onAnswer(question: Question, value: number): void {
    let currentAnswerIndex = this.currentAnswers.findIndex(answer => answer.getId() === question.getId());
    if (currentAnswerIndex === -1) {
      this.currentAnswers.push(new Answer().setId(question.getId()));
      currentAnswerIndex = this.currentAnswers.length - 1;
    }
    this.currentAnswers[currentAnswerIndex].setValue(value);

    this.currentPerson.person.setAnswersList(this.currentAnswers);
    this.personChange.emit(this.persons);
  }

  getCurrentValueForQuestion(question: Question): number {
    const currentAnswerIndex = this.currentAnswers.findIndex(answer => answer.getId() === question.getId());
    if (currentAnswerIndex === -1) {
      return 0;
    }
    return this.currentAnswers[currentAnswerIndex].getValue();
  }

  getQuestionCardOffset(index: number): number {
    const offset = index * 100 - this.currentQuestionGroup * 100;
    return offset >= 0 ? offset : -100;
  }

  prev(): void {
    if (this.currentQuestionGroup - 1 < 0) {
      this.currentPersonIndex -= 1;
      this.currentAnswers = this.importantPersons[this.currentPersonIndex].person.getAnswersList() ?? [];
      this.currentPersonIndexChange.emit(this.currentPersonIndex);
      this.currentQuestionGroup = this.questionGroups.length - 1;
    } else {
      this.currentQuestionGroup -= 1;
    }
    this.currentQuestionGroupChange.emit(this.currentQuestionGroup);
  }

  next(): void {
    if (this.currentQuestionGroup + 1 >= this.questionGroups.length) {
      this.currentPersonIndex += 1;
      this.currentAnswers = this.importantPersons[this.currentPersonIndex].person.getAnswersList() ?? [];
      this.currentPersonIndexChange.emit(this.currentPersonIndex);
      this.currentQuestionGroup = 0;
    } else {
      this.currentQuestionGroup += 1;
    }
    this.currentQuestionGroupChange.emit(this.currentQuestionGroup);
  }

  isStepReady(): boolean {
    const currentQuestions = this.questionGroups[this.currentQuestionGroup].getQuestionsList();
    return currentQuestions.every(question => this.currentAnswers.findIndex(answer => answer.getId() === question.getId()) !== -1);
  }

  isAtStart(): boolean {
    return this.currentQuestionGroup === 0 && this.currentPersonIndex === 0;
  }

  isAtEnd(): boolean {
    return this.currentPersonIndex + 1 >= (this.importantPersons?.length ?? 0) &&
      this.currentQuestionGroup + 1 >= (this.questionGroups?.length ?? 0);
  }

}
