import {Component} from '@angular/core';
import {SurveyService} from '../../services/survey.service';
import {Answer, Circle, QuestionGroup, Survey} from '../../../grpc/api_pb';
import {CryptoService} from '../../services/crypto.service';

@Component({
  selector: 'app-example',
  templateUrl: './example.component.html',
  styleUrls: ['./example.component.scss']
})
export class ExampleComponent {

  groups?: QuestionGroup[];
  circle?: Circle;

  constructor(private surveyService: SurveyService, private cryptoService: CryptoService) {
    surveyService.getQuestions('de').subscribe(groups => this.groups = groups);
    surveyService.getCircle(0, 'de').subscribe(circle => this.circle = circle);

    // For generating demo data (1577861103 = 1.1.2020)
    // surveyService.saveSurvey(new Survey().setComment('comment').setAbbreviation('AA')
    //   .setPersonsList([
    //     new Person().setAbbreviation('BB').setCircleNr(1).setContactFrequency(3).setKnownSinceMonths(3)
    //       .setRelationship(4).setAge(2).setFullName('Max Muster 1').setAnswersList([
    //       new Answer().setId(1).setValue(2),
    //       new Answer().setId(2).setValue(1),
    //       new Answer().setId(3).setValue(3),
    //       new Answer().setId(4).setValue(4),
    //       new Answer().setId(5).setValue(1),
    //       new Answer().setId(6).setValue(1),
    //       new Answer().setId(11).setValue(1),
    //       new Answer().setId(12).setValue(2),
    //       new Answer().setId(13).setValue(3),
    //       new Answer().setId(14).setValue(2),
    //     ]),
    //     new Person().setAbbreviation('CC').setCircleNr(2).setContactFrequency(3).setKnownSinceMonths(3)
    //       .setRelationship(4).setAge(2).setFullName('Max Muster 2').setAnswersList([
    //       new Answer().setId(1).setValue(2),
    //       new Answer().setId(2).setValue(1),
    //       new Answer().setId(3).setValue(3),
    //       new Answer().setId(4).setValue(4),
    //       new Answer().setId(5).setValue(1),
    //       new Answer().setId(6).setValue(1),
    //       new Answer().setId(11).setValue(1),
    //       new Answer().setId(12).setValue(2),
    //       new Answer().setId(13).setValue(3),
    //       new Answer().setId(14).setValue(2),
    //     ]),
    //   ])).subscribe();

  }

}
