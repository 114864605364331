<h3>Questions</h3>

<ul>
  <li *ngFor="let group of groups">
    {{ group.getTitle() }}
    <ol>
      <li *ngFor="let question of group.getQuestionsList()">
        {{ question.getTitle() }}
      </li>
    </ol>
  </li>
</ul>

<h3>Circle</h3>

<!-- TODO: Update for past -->
<ol>
  <li *ngFor="let title of circle?.getTitlesNowList()">
    {{ title }}
  </li>
</ol>
