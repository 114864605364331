import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {PersonExtra} from '../../model/person-extra';
import {UntypedFormControl, Validators} from '@angular/forms';
import {PersonWrapper} from '../../model/person-wrapper';
import {Circle, ContactFrequency, Relationship, YesNoMaybe} from '../../../grpc/api_pb';
import {TranslocoService} from '@ngneat/transloco';
import {EnumTranslator} from '../../util/enum-translator';
import {ModalHelper} from '../../util/modal-helper';
import {MatDatepicker} from '@angular/material/datepicker';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-person-add',
  templateUrl: './person-add.component.html',
  styleUrls: ['./person-add.component.scss']
})
export class PersonAddComponent implements OnInit {

  YesNoMaybe = YesNoMaybe;
  readonly relationshipTypes = [
    Relationship.PARTNER,
    Relationship.EX_PARTNER,
    Relationship.CHILD,
    Relationship.PARENTS,
    Relationship.SIBLING,
    Relationship.RELATIVE,
    Relationship.BEST_FRIEND,
    Relationship.FRIEND,
    // Relationship.BETTER_KNOWN_PERSON,
    Relationship.COLLEAGUE,
    // Relationship.SUPERVISOR,
    Relationship.PROFESSIONAL,
    Relationship.OTHER_RELATIONSHIP
  ];
  readonly frequencies = [
    ContactFrequency.DAILY,
    ContactFrequency.SEVERAL_TIMES_A_WEEK,
    ContactFrequency.ONCE_A_WEEK,
    ContactFrequency.SEVERAL_TIMES_A_MONTH,
    ContactFrequency.ONCE_A_MONTH,
    ContactFrequency.SEVERAL_TIMES_A_YEAR,
    ContactFrequency.ONCE_A_YEAR,
    ContactFrequency.LESS
  ];


  readonly NAME = 0;
  readonly PERSON_GROUP = 1;
  readonly GENDER = 2;
  readonly AGE = 3;
  readonly RELATIONSHIP = 4;
  readonly RELATIONSHIP_DURATION = 5;
  readonly FREQUENCY_OF_CONTACT = 6;
  // TODO: Properly implement new questions and increase step count
  readonly IS_QUEER = 7;
  readonly KNOWS_ABOUT_TRANS = 8;
  readonly WOULD_CHANGE_OPINION_IF_NOT_TRANS = 9;

  readonly STEP_COUNT_NOW = 10;
  readonly STEP_COUNT_PAST = 2;

  stepCount!: number;
  currentStep = 0;

  data!: PersonAddData;

  nameControl = new UntypedFormControl(null, Validators.required);
  isGroupControl = new UntypedFormControl(null, Validators.required);
  groupSizeControl = new UntypedFormControl(null, Validators.required);
  genderControl = new UntypedFormControl(null, Validators.required);
  ageControl = new UntypedFormControl(null, Validators.required);
  relationshipTypeControl = new UntypedFormControl(null, Validators.required);
  relationshipOtherTextControl = new UntypedFormControl(null, Validators.required);
  relationshipDurationControl = new UntypedFormControl(null, Validators.required);
  isQueerControl = new UntypedFormControl(null, Validators.required);
  knowsAboutTransControl = new UntypedFormControl(null, Validators.required);
  wouldChangeOpinionIfNotTransControl = new UntypedFormControl(null, Validators.required);
  durationMonth?: Date;
  now = new Date();
  frequencyOfContactControl = new UntypedFormControl(null, Validators.required);

  isCancelling = false;

  constructor(
    private ts: TranslocoService,
    @Optional() private bottomSheetRef?: MatBottomSheetRef,
    @Optional() private dialogRef?: MatDialogRef<PersonAddComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) bottomSheetData?: PersonAddData,
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData?: PersonAddData
  ) {
    ModalHelper.disableBackdropClose(dialogRef, bottomSheetRef);
    this.data = ModalHelper.extractData<PersonAddData>(dialogData, bottomSheetData);
    this.stepCount = this.data.isInPast ? this.STEP_COUNT_PAST : this.STEP_COUNT_NOW;
  }

  ngOnInit(): void {
  }

  prev(): void {
    this.currentStep--;
    if (this.currentStep === this.GENDER && this.isGroupControl.value === true) {
      this.currentStep--;
    }
  }

  next(): void {
    this.currentStep++;

    if (this.currentStep === this.GENDER && this.isGroupControl.value === true) {
      this.currentStep++;
    }
    if (this.currentStep >= this.stepCount) {
      this.close(true);
    }
  }

  close(shouldAdd: boolean, force = false): void {
    if (shouldAdd) {
      this.data.personWrapper.hasAnsweredPersonDetails = true;
      if (this.data.isInPast) {
        this.data.personWrapper.person.setNamePast(this.nameControl.value);
        this.data.personWrapper.person.setIsGroupPast(this.isGroupControl.value);
      } else {
        this.data.personWrapper.person.setFullName(this.nameControl.value);
        this.data.personWrapper.person.setIsGroup(this.isGroupControl.value);
      }
      if (this.isGroupControl.value) {
        this.data.personWrapper.person.setGroupSize(this.groupSizeControl.value);
      }
      this.data.personWrapper.person.setGender(this.genderControl.value);
      this.data.personWrapper.person.setAge(this.ageControl.value);
      this.data.personWrapper.person.setRelationship(this.relationshipTypeControl.value);
      if (this.isOtherRelationShipType()) {
        this.data.personWrapper.person.setOtherRelationship(this.relationshipOtherTextControl.value);
      }
      this.data.personWrapper.person.setKnownSinceMonths(this.relationshipDurationControl.value);
      this.data.personWrapper.person.setContactFrequency(this.frequencyOfContactControl.value);
      this.data.personWrapper.person.setIsQueer(this.isQueerControl.value);
      this.data.personWrapper.person.setKnowsAboutTrans(this.knowsAboutTransControl.value);
      this.data.personWrapper.person.setWouldChangeOpinionIfNotTrans(this.wouldChangeOpinionIfNotTransControl.value);
    } else {
      if (this.currentStep !== 0 && !force) {
        this.isCancelling = true;
        return;
      }
    }
    ModalHelper.close(this.dialogRef, this.bottomSheetRef, new PersonAddResult(shouldAdd, this.data.personWrapper));
  }

  isStepReady(): boolean {
    switch (this.currentStep) {
      case this.NAME:
        return this.nameControl.valid;
      case this.PERSON_GROUP:
        return this.isGroupControl.valid && (!this.isGroupControl.value || this.groupSizeControl.valid);
      case this.GENDER:
        return this.genderControl.valid;
      case this.AGE:
        return this.ageControl.valid;
      case this.RELATIONSHIP:
        return this.relationshipTypeControl.valid &&
          (!this.isOtherRelationShipType() || this.relationshipOtherTextControl.valid);
      case this.RELATIONSHIP_DURATION:
        return this.relationshipDurationControl.valid;
      case this.FREQUENCY_OF_CONTACT:
        return this.frequencyOfContactControl.valid;
      case this.IS_QUEER:
        return this.isQueerControl.valid;
      case this.KNOWS_ABOUT_TRANS:
        return this.knowsAboutTransControl.valid;
      case this.WOULD_CHANGE_OPINION_IF_NOT_TRANS:
        return this.wouldChangeOpinionIfNotTransControl.valid;
    }
    return true;
  }

  isOtherRelationShipType(): boolean {
    return this.relationshipTypeControl.value === Relationship.OTHER_RELATIONSHIP;
  }

  getAvatarPath(): string {
    return PersonWrapper.getAvatarPath(this.isGroupControl.value);
  }

  getRelationshipTypeName(relationship: Relationship): string {
    return EnumTranslator.getTextFromRelationship(this.ts, relationship);
  }

  getFrequencyName(frequency: ContactFrequency): string {
    return EnumTranslator.getTextFromContactFrequency(this.ts, frequency);
  }

  getYesNoMaybeName(yesNoMaybe: YesNoMaybe): string {
    return EnumTranslator.getYesNoMaybeName(this.ts, yesNoMaybe, this.data.circleType);
  }

  onDurationYearChosen(date: Date): void {
    this.durationMonth = new Date();
    this.durationMonth?.setFullYear(date.getFullYear());
  }

  onDurationMonthChosen(date: Date, datePicker: MatDatepicker<unknown>): void {
    this.durationMonth?.setMonth(date.getMonth());
    datePicker.close();
  }

  onDateChosen(): void {
    if (this.durationMonth === undefined) {
      return;
    }
    this.relationshipDurationControl.setValue(this.monthDiff(this.durationMonth, new Date()));
  }

  getDurationDateAsString(): string {
    return this.durationMonth !== undefined ?
      ('0' + (this.durationMonth.getMonth() + 1)).slice(-2) + '.' + this.durationMonth.getFullYear() : '';
  }

  monthDiff(dateFrom: Date, dateTo: Date): number {
    return dateTo.getMonth() - dateFrom.getMonth() +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
  }

}

export class PersonAddData {
  personWrapper: PersonWrapper;
  circleType: CircleType;
  isInPast: boolean;

  constructor(personWrapper: PersonWrapper, circleType: CircleType, isInPast: boolean) {
    this.personWrapper = personWrapper;
    this.circleType = circleType;
    this.isInPast = isInPast;
  }
}

export type CircleType = 'support' | 'appreciation';

export class PersonAddResult {
  shouldAdd: boolean;
  person: PersonWrapper;

  constructor(shouldAdd: boolean, person: PersonWrapper) {
    this.shouldAdd = shouldAdd;
    this.person = person;
  }
}
