import {Injectable} from '@angular/core';
import {BaseGrpcService} from './base-grpc-service';
import {AdminServiceClient} from '../../grpc/ApiServiceClientPb';
import {Observable, of, ReplaySubject} from 'rxjs';
import {
  AdminCircle,
  Empty,
  ExportSurveysRequest,
  ExportSurveysResponse,
  GetAdminCirclesResponse,
  IdRequest,
  LoginRequest,
  SearchSurveysRequest,
  SearchSurveysResponse,
  SurveyView
} from '../../grpc/api_pb';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseGrpcService {

  private client: AdminServiceClient;
  private authenticated?: boolean;

  stateChange = new ReplaySubject<boolean>(1);

  constructor(private router: Router) {
    super();
    this.client = new AdminServiceClient('');
    this.isAuthenticated().subscribe(authenticated => this.stateChange.next(authenticated));
  }

  protected onSessionInvalidation(): void {
    // this.router.navigate(['/login']);
  }

// returns true when the user is authenticated. Tries to check the session when the current state is unclear (page reload).
  private isAuthenticated(): Observable<boolean> {
    if (this.authenticated === undefined) {
      return this.state().pipe(map(() => {
        this.authenticated = true;
        return this.authenticated;
      }), catchError(() => {
        this.authenticated = false;
        return of(this.authenticated);
      }));
    }
    return of(this.authenticated);
  }

  login(username: string, password: string): Observable<void> {
    return this.callGrpc<Empty>(callback => this.client.login(
      new LoginRequest().setUsername(username).setPassword(password), null, callback)).pipe(
      map(() => {
        this.authenticated = true;
        this.stateChange.next(this.authenticated);
      }),
      catchError(err => {
        this.authenticated = false;
        this.stateChange.next(this.authenticated);
        throw err;
      }));
  }

  logout(): Observable<void> {
    this.authenticated = false;
    this.stateChange.next(this.authenticated);
    return this.callGrpc<Empty>(callback => this.client.logout(
      new Empty(), null, callback)).pipe(map(() => {
      return;
    }));
  }

  state(): Observable<void> {
    return this.callGrpc<Empty>(callback => this.client.state(
      new Empty(), null, callback)).pipe(map(
      () => {
        return;
      }));
  }

  searchSurveys(abbreviation: string, dateOfBirth: Date): Observable<SurveyView[]> {
    return this.callGrpc<SearchSurveysResponse>(callback => this.client.searchSurveys(
      new SearchSurveysRequest()
        .setAbbreviation(abbreviation), null, callback))
      .pipe(map(value => value.getSurveysList()));
  }

  exportSurveys(ids?: number[]): Observable<Array<Uint8Array | string>> {
    return this.callGrpc<ExportSurveysResponse>(callback => this.client.exportSurveys(
      new ExportSurveysRequest().setIdsList(ids ?? []), null, callback))
      .pipe(map(value => value.getRecordsList()));
  }

  getCircles(): Observable<AdminCircle[]> {
    return this.callGrpc<GetAdminCirclesResponse>(callback => this.client.getAdminCircles(
      new Empty(), null, callback)).pipe(map(value => value.getCirclesList()));
  }

  updateCircle(circle: AdminCircle): Observable<AdminCircle> {
    return this.callGrpc<AdminCircle>(callback => this.client.updateCircle(
      circle, null, callback));
  }

  deleteCircle(id: number): Observable<void> {
    return this.callGrpc<Empty>(callback => this.client.deleteCircle(
      new IdRequest().setId(id), null, callback)).pipe(map(value => {
      return;
    }));
  }

}
